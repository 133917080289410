import React from 'react';

export default function TextWithImageBackGround({ imageUrl, children, style, ...props }) {
    const containerStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        ...style,
    };

    return (
        <div
            {...props}
            style={containerStyle}
        >
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', color: '#fff' }}>
                {children}
            </div>
        </div>
    );
}