import React, { useEffect, useRef } from "react";
import SimplePopup from "./SimplePopup";
import { colors } from "../../styles/colors";

export default function SimpleDialog({ isOpen, closeDialogFunction, closeOnOutsideClick = false, children }) {

    const isMountedRef = useRef(false);

    useEffect(() => {
        isMountedRef.current = true;
    }, []);


    return (
        <SimplePopup
            closePopupFunction={closeOnOutsideClick ? closeDialogFunction : () => { }}
            isOpen={isOpen}
            style={{
                backgroundColor: colors.halfTransparentBlack,
                height: "100%",
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {children}
        </SimplePopup>
    );
}
