import React, { forwardRef } from "react";
import { useMediaQuery } from "react-responsive";
import { SimpleContainer } from "../../../components/simpleComponents/SimpleContainer";
import CardWithLogoTitleAndContent from "../components/CardWithLogoTitleAndContent";
import { MainScreenData } from "../MainScreenData";
import { Text24 } from "../../../components/styledComponents/texts/Text24";

const { Page5Data } = MainScreenData;

const Page5 = forwardRef((props, ref) => {
    const isSmallScreen = useMediaQuery({ maxWidth: 1280 });

    return (
        <SimpleContainer style={styles.container(isSmallScreen)} ref={ref}>
            {isSmallScreen ? (
                <SimpleContainer style={styles.innerContainer}>
                    <Text24 style={{ textAlign: 'center' }}>למה FolloApp?</Text24>
                    <CardWithLogoTitleAndContent
                        ListOfCards={Page5Data}
                        style={{ paddingTop: 24, justifyContent:'center'}}
                    />
                </SimpleContainer>
            ) : (
                <SimpleContainer>
                    {/* Add your content for larger screens here */}
                </SimpleContainer>
            )}
        </SimpleContainer>
    );
});

const styles = {
    container: (isSmallScreen) => ({
        paddingLeft: isSmallScreen ? 20 : 160,
        paddingRight: isSmallScreen ? 20 : 160,
        paddingTop: 40, // Adjust based on the height of the header
        paddingBottom: 40,
        background: '#FFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    }),
    innerContainer: {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
};

export default Page5;
