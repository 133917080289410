import React from 'react';
import SimpleImage from './SimpleImage';

export default function SimpleIcon({ source, size, tintColor, style }) {
    if (!source) {
        return null;
    }

    const iconStyle = {
        width: size,
        height: size,
        ...style,
        filter: tintColor ? `brightness(0) saturate(100%) invert(${tintColor})` : undefined,
    };

    return (
        <SimpleImage
            src={source}
            style={iconStyle}
        />
    );
}
