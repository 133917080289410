import React from 'react';

export default function SimpleText({ children, ...props }) {

    return (
        <div
            {...props}
        >
            {children}
        </div>
    );
}

