import React from "react";
import { SimpleContainer } from "../../simpleComponents/SimpleContainer";
import { colors } from "../../../styles/colors";

const THICKNESS = 1;

export default function Separator({ vertical = false, margin = 0 }) {
    const separatorStyle = {
        backgroundColor: colors.secondaryHighlighted,
        ...(vertical ? { width: THICKNESS, margin: `${margin}px 0` } : { height: THICKNESS, margin: `0 ${margin}px` }),
    };

    return <SimpleContainer style={separatorStyle}></SimpleContainer>;
}
