import React from 'react';

export const SimpleContainer = React.forwardRef(({ children, onClick, style }, ref) => {
    const containerStyle = {
        display: 'flex',
        ...style,
    };

    return (
        <div ref={ref} style={containerStyle} onClick={onClick}>
            {children}
        </div>
    );
});
