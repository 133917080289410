import { colors } from "../../../styles/colors";
import SimpleText from "../../simpleComponents/SimpleText";

export default function DefaultText({ children, style, ...props }) {
    if (!children && children !== 0 && children !== '') {
        return null;
    }

    const textStyle = {
        ...styles.text,
        ...style,
    };

    return (
        <SimpleText {...props} style={textStyle}>
            {children}
        </SimpleText>
    );
}

const styles = {
    text: {
        textAlign: 'right',
        color: colors.text,
    }
};
