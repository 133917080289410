import React, { forwardRef } from "react";
import { useMediaQuery } from "react-responsive";
import { SimpleContainer } from "../../../components/simpleComponents/SimpleContainer";
import ImageWithLabelAndContent from "../components/ImageWithLabelAndContent";
import { MainScreenData } from "../MainScreenData";
import { Text60 } from "../../../components/styledComponents/texts/Text60";

const { Page3: Page3Data } = MainScreenData;

const Page3 = forwardRef((props, ref) => {
    const isSmallScreen = useMediaQuery({ maxWidth: 1280 });

    const containerStyles = styles.container(isSmallScreen);
    const textContainerStyles = styles.textContainer;
    const contentContainerStyles = styles.contentContainer;
    const bigContainerStyles = styles.bigContainer;

    return (
        <SimpleContainer ref={ref} style={containerStyles}>
            {isSmallScreen ? (
                <SimpleContainer style={textContainerStyles}>
                    <ImageWithLabelAndContent
                        ListOf={Page3Data}
                        ContentStyle={contentContainerStyles}
                        ContainerStyle={textContainerStyles}
                    />
                </SimpleContainer>
            ) : (
                <SimpleContainer style={textContainerStyles}>
                    <Text60 style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>{`כל מה שצריך כדי לקבל\nהחלטות פיננסיות טובות יותר`}</Text60>
                    <SimpleContainer style={bigContainerStyles}>
                        {[0, 2, 4].map((startIndex, index) => (
                            <ImageWithLabelAndContent
                                key={index}
                                ListOf={Page3Data.slice(startIndex, startIndex + 2)}
                                ContentStyle={contentContainerStyles}
                                ContainerStyle={styles.textContainerBig(index % 2)}
                            />
                        ))}
                    </SimpleContainer>
                </SimpleContainer>
            )}
        </SimpleContainer>
    );
});

const styles = {
    container: (isSmallScreen) => ({
        paddingLeft: isSmallScreen ? 20 : 80,
        paddingRight: isSmallScreen ? 20 : 80,
        paddingTop: isSmallScreen ? 40 : 96,
        paddingBottom: isSmallScreen ? 40 : 96,
        background: '#FFF2E2',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden', // Prevent horizontal overflow
        flex:1
    }),
    textContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 24,
    },
    bigContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 84,
    },
    textContainerBig: (index) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        paddingLeft: index ? 68 : 0,
        paddingRight: index ? 68 : 0,
    }),
};

export default Page3;
