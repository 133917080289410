import React, { forwardRef } from 'react';
import { useMediaQuery } from "react-responsive";
import { SimpleContainer } from "../../../components/simpleComponents/SimpleContainer";
import { Text16 } from "../../../components/styledComponents/texts/Text16";
import TextMedium16 from "../../../components/styledComponents/texts/TextMedium16"; 
import { Text24 } from "../../../components/styledComponents/texts/Text24";
import { images } from "../../../assets/images/images";
import SimpleImage from "../../../components/simpleComponents/SimpleImage";
import ImageButton from "../../../components/simpleComponents/ImageButton";
import { Text60 } from "../../../components/styledComponents/texts/Text60";
import CardWithLogoTitleAndContent from "../components/CardWithLogoTitleAndContent";
import { MainScreenData } from "../MainScreenData";

const {Page5Data} = MainScreenData

const Page4 = forwardRef(({ refs }, ref) => {
    const isSmallScreen = useMediaQuery({ maxWidth: 1280 });

    function handleLinksPress(src){
        if (src === 'Google') {
            window.location.href = 'https://did.li/jfRNf';
        } else {
            window.location.href = 'https://did.li/Wpjrl';
        }
    }

    return (
        <SimpleContainer ref={refs.InsightAndRecommendetion} style={styles.container(isSmallScreen)}>
            {isSmallScreen ? (
                <SimpleContainer style={styles.textContainer}>
                    <Text24 style={{ textAlign: 'center', whiteSpace: 'pre-line'}}>
                        {`שימוש מהמחשב או\n מהנייד לנוחיותכם`}
                    </Text24>
                    <SimpleImage src={images.Computer.DesktopComputer} style={{width:'100%', marginTop:24}}/>
                    <SimpleImage src={images.Backgrounds.LongLogosBackPage4} style={{display: 'flex', height: '44px', justifyContent: 'center', marginTop:24}}/>
                    <Text24 style={{ textAlign: 'center', marginTop: 24, whiteSpace: 'pre-line'}}>
                        {`תובנות פיננסיות\nמשמעותיות לחיים`}
                    </Text24>
                    <TextMedium16 style={{ textAlign: 'center', marginTop: 24 }}>
                        {`כל מה שנחוץ זה 5 דקות של הרשמה באפליקציה, ואז Folloapp עובדת בשבילכם`}
                    </TextMedium16>
                    <Text16 style={{ textAlign: 'center', marginTop: 24, whiteSpace: 'pre-line'}}>{`Folloapp תפשט את המעקב השוטף על קרנות פנסיה, גמל והשתלמות, ביטוחים קיימים ותתאים אותם לשינויים בשכר, מצב רפואי ועוד.\n\n
Folloapp מתאימה את ניהול החסכונות שלך לצרכייך האישיים בכל רגע.`}</Text16>
                    <Text16 style={{ textAlign: 'center', marginTop: 32 }}>
                        להורדת האפליקציה לחצו כאן -
                    </Text16>
                    <SimpleContainer style={styles.buttonContainer}>
                        <ImageButton
                            key="apple-button"
                            image={images.Buttons.AppleDownload}
                            style={{ width: 150, height: 44, marginLeft: 8}}
                            onPress={() => handleLinksPress('Apple')}
                        />
                        <ImageButton
                            key="google-button"
                            image={images.Buttons.GoogleDownload}
                            style={{ width: 150, height: 44, marginRight: 8 }}
                            onPress={() => handleLinksPress('Google')}
                        />
                    </SimpleContainer>
                    <SimpleImage src={images.Phones.Page4Phone} style={{ width: 342, height: 448, marginTop:44}}/>
                </SimpleContainer>
            ) : (
                <SimpleContainer style={styles.textContainerBig}>
                    <SimpleContainer style={{alignItems:'center'}}>
                        <SimpleImage src={images.Phones.Page4Phone} style={{width: 764, height: 997}}/>
                        <SimpleContainer style={{flex:1, display: 'flex', flexDirection: 'column'}}>
                            <Text60>{`תובנות והמלצות שמתאימות לך`}</Text60>
                            <Text24 style={{marginTop:32}}>{`אנחנו מנתחים את הנתונים הפיננסיים שלך ומפיקים עבורך תובנות והמלצות באופן שוטף, שמותאמות לרצונות ולצרכים שלך.`}</Text24>
                            <Text24 style={{marginTop:32, whiteSpace: 'pre-line', }}>{`באילו נושאים תקבל/י מאתנו תובנות והמלצות?\n\n - פעילות בחשבון הבנק\n - התנהלות בעולמות האשראי\n - קרנות פנסיה, קופות גמל והשתלמות\n - כיסויים ביטוחיים\n - הלוואות ומשכנתאות\n - חסכונות, השקעות ועוד`}</Text24>
                            <Text16 style={{marginTop:32}}>{`להורדת האפליקציה:`}</Text16>
                            <SimpleContainer style={styles.buttonContainerBig}>
                                <ImageButton
                                    key="apple-button"
                                    image={images.Buttons.AppleDownload}
                                    style={{ width: 150, height: 44, marginLeft: 8}}
                                    onPress={() => handleLinksPress('Apple')}
                                />
                                <ImageButton
                                    key="google-button"
                                    image={images.Buttons.GoogleDownload}
                                    style={{ width: 150, height: 44, marginRight: 8 }}
                                    onPress={() => handleLinksPress('Google')}
                                />
                            </SimpleContainer>
                        </SimpleContainer>
                    </SimpleContainer>
                    <SimpleContainer style={{ width: '100%', flexDirection: 'column' }} ref={refs.WhyFolloApp}>
                        <Text60 style={{textAlign: 'center', paddingTop:96}}>למה FolloApp?</Text60>
                        <SimpleContainer style={styles.BigContainer}>
                            <CardWithLogoTitleAndContent
                                ListOfCards={Page5Data.slice(0,2)}
                                style={{width: '100%',}}
                            />
                            <CardWithLogoTitleAndContent
                                ListOfCards={Page5Data.slice(2,4)}
                                style={{marginRight:16,marginLeft:16, width: '100%',}}
                            />
                            <CardWithLogoTitleAndContent
                                ListOfCards={Page5Data.slice(4,6)}
                                style={{width: '100%',}}
                            />
                        </SimpleContainer>

                    </SimpleContainer>
                </SimpleContainer>
            )}
        </SimpleContainer>
    );
});

const styles = {
    container: (isSmallScreen) => ({
        paddingLeft: isSmallScreen ? 20 : 80,
        paddingRight: isSmallScreen ? 20 : 80,
        paddingTop: 40, // Adjust based on the height of the header
        paddingBottom: isSmallScreen ? 25 : 96,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        background: '#FFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden', // Prevent horizontal overflow
        flex:1

    }),
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width:'100%'
    },
    textContainerBig: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width:'100%'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 16,
    },
    buttonContainerBig: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 16,
    },
    MainContiner: {
        display: 'flex',
        justifyContent: 'center',
    },
    BigContainer:{
        display: 'flex',
        flex:1,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop:84,
    },
    ContentContainerBig: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:96
    },
    
};

export default Page4;
