import React, { useState } from 'react';
import { defaultFont } from '../../../../assets/fonts/defaultFont';
import { colors } from '../../../../styles/colors';

const SimpleTextInput = React.forwardRef(
    (
        {
            style,
            onValueChange,
            delayInMillis,
            disableAnalyticsEvents = false,
            value,
            placeholder,
            maxRows = 6,  // New prop for maxRows
            ...props
        },
        ref
    ) => {
        const [delayTimer, setDelayTimer] = useState(null);

        function handleTextChange(newText) {
            trackStartedTypingEvent(newText);
            timerSearch(newText);
        }

        function timerSearch(newText) {
            if (!delayInMillis) {
                onValueChange?.(newText);
            } else {
                clearTimeout(delayTimer);
                setDelayTimer(
                    setTimeout(() => {
                        onValueChange?.(newText);
                    }, delayInMillis)
                );
            }
        }

        function trackStartedTypingEvent(newText) {
            const isNewTextEmpty = isEmptyText(newText);
        }

        function isEmptyText(text) {
            return !text || text === '';
        }

        const textInputStyle = {
            fontFamily: defaultFont.regular,
            ...styles.textInput,
            ...style,
            resize: 'none', // Disable manual resizing by the user
            overflowY: 'hidden', // Hide scrollbar and limit text to max lines
        };

        function handleInput(event) {
            // Ensure the textarea does not exceed 6 lines
            const element = event.target;
            const lines = element.value.split('\n').length;

            if (lines <= 6) {
                handleTextChange(element.value);
            } else {
                // If exceeding 6 lines, keep only the first 6 lines of text
                const trimmedValue = element.value.split('\n').slice(0, 6).join('\n');
                handleTextChange(trimmedValue);
                element.value = trimmedValue; // Update the displayed value
            }
        }

        return (
            <div style={textInputStyle}>
                <textarea
                    ref={ref}
                    value={value}
                    onChange={handleInput}
                    placeholder={placeholder}
                    style={textInputStyle}
                    rows={6} // Suggest height for 6 lines of text
                    {...props}
                />
            </div>
        );
    }
);

export default SimpleTextInput;

const styles = {
    textInput: {
        borderWidth: 1,
        borderColor: colors.transparent,
        borderRadius: 8,
        fontSize: 16,
        color: colors.text,
        backgroundColor: colors.white,
        marginBottom: 10,
        outline: 'none',
        padding: '8px',
    },
};
