import React from 'react';
import { colors } from '../../../../styles/colors';
import SimpleText from '../../../../components/simpleComponents/SimpleText';


const styles = {
    errorText: {
        color: colors.errorHighlighted, // Error text color
    },
};

export default function ErrorText({ children, style }) {
    if (!children) {
        return null;
    }

    const errorTextStyle = {
        ...styles.errorText,
        ...style,
    };

    return (
        <SimpleText style={errorTextStyle}>
            {children}
        </SimpleText>
    );
}
