import { useMediaQuery } from "react-responsive";
import { SimpleContainer } from "../../../components/simpleComponents/SimpleContainer";
import { Text16 } from "../../../components/styledComponents/texts/Text16";
import { Text24 } from "../../../components/styledComponents/texts/Text24";
import { images } from "../../../assets/images/images";
import SimpleImage from "../../../components/simpleComponents/SimpleImage";

export default function Page2() {
    const isSmallScreen = useMediaQuery({ maxWidth: 1050 });

    return (
        <SimpleContainer style={styles.container(isSmallScreen)}>
            {isSmallScreen ? (
                <SimpleContainer style={styles.textContainer}>
                    <Text24 style={{ textAlign: 'center', whiteSpace: 'pre-line'}}>
                    {`כל הפעילות הפיננסית\n שלך תחת קורת גג אחת`}
                    </Text24>
                    <Text16 style={{ textAlign: 'center', marginTop: 24 }}>
                    FolloApp מציגה תמונה מלאה ועדכנית של הפעילות הפיננסית שלך: ביטוחים, פנסיה, השקעות, התחייבויות, תזרים ועוד
                    </Text16>
                    <SimpleImage src={images.Phones.Page2Phone} style={{width:'100%', marginTop:24}}/>
                </SimpleContainer>
            ):(
                    <SimpleContainer style={styles.buttonContainer}>
                        <SimpleContainer style={styles.textContainerBig}>
                            <Text24 style={{marginTop: 40 }}>
                            כל הפעילות הפיננסית שלך תחת קורת גג אחת
                            </Text24>
                            <Text16 style={{  marginTop: 24, whiteSpace: 'pre-line'}}>
                                {'FolloApp מציגה תמונה מלאה ועדכנית של הפעילות הפיננסית שלך: \n\n - ביטוחים \n - פניסה \n - השקעות \n - התחייבויות \n - תזרים \n - ועוד'}
                            </Text16>
                        </SimpleContainer>
                        <SimpleContainer style={{flex:1}}>
                            <SimpleImage src={images.Phones.Page2Phone} style={{width:652, height:829, marginRight:72}}/>
                        </SimpleContainer>
                    </SimpleContainer>
            )}

        </SimpleContainer>
    )
}

const styles = {
    container: (isSmallScreen) => ({
        paddingLeft: isSmallScreen ? 20 : 80,
        paddingRight: isSmallScreen ? 20 : 80,
        paddingTop: 40, // Adjust based on the height of the header
        paddingBottom:40,
        background: '#FFF',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden', // Prevent horizontal overflow
        flex:1
    }),
    textContainer: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    textContainerBig: {
        flexDirection: 'column',
        alignItems: 'center',
        flex:1
    },  
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 16,
        width:'100%'
    },
    MainContiner: {
        display: 'flex',
        justifyContent: 'center',
    },
};
