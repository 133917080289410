import React, { forwardRef } from 'react';
import { SimpleContainer } from '../../../components/simpleComponents/SimpleContainer';
import { images } from '../../../assets/images/images';
import { useMediaQuery } from 'react-responsive';
import { Text16 } from '../../../components/styledComponents/texts/Text16';
import ImageButton from '../../../components/simpleComponents/ImageButton';
import SimpleImage from '../../../components/simpleComponents/SimpleImage';
import TextMedium40 from '../../../components/styledComponents/texts/TextMedium40.js';
import TextMedium20 from '../../../components/styledComponents/texts/TextMedium20';
import TextWithImageBackGround from '../../../components/styledComponents/texts/TextWithImageBackGround.js';
import TextMedium24 from '../../../components/styledComponents/texts/TextMedium24.js';
import TextMedium80 from '../../../components/styledComponents/texts/TextMedium80.js';

const Page1 = forwardRef((props, ref) => {
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });

    function handleLinksPress(src) {
        if (src === 'Google') {
            window.location.href = 'https://did.li/jfRNf';
        } else {
            window.location.href = 'https://did.li/Wpjrl';
        }
    }    

    return (
        <SimpleContainer ref={ref} style={styles.container(isSmallScreen)}>
            {isSmallScreen ? (
                <SimpleContainer style={styles.textContainer}>
                    <TextMedium40 style={{ textAlign: 'center', whiteSpace: 'pre-line'}}>
                        {`פשוט לקבל\nהחלטות פיננסיות`}
                    </TextMedium40>
                    <SimpleContainer style={{alignItems:'center'}}>
                    <TextWithImageBackGround imageUrl={images.Backgrounds.Circle} style={{width:140,height:64}}><TextMedium40>טובות</TextMedium40></TextWithImageBackGround>
                        <TextMedium40 style={{ textAlign: 'center', whiteSpace: 'pre-line'}}>
                            {`יותר`}
                        </TextMedium40>
                    </SimpleContainer>
                    <TextMedium20 style={{flex:1, textAlign: 'center', marginTop: 32, marginLeft:20, marginRight:20}}>
                    FolloApp מרכזת את כל הפעילות הפיננסית שלך תחת קורת גג אחת, מפיקה תובנות והמלצות מותאמות אישית ומוציאה לפועל כל החלטה שלך
                    </TextMedium20>
                    <Text16 style={{ textAlign: 'center', marginTop: 32 }}>
                            להורדת האפליקציה:
                    </Text16>
                    <SimpleContainer style={styles.buttonContainer}>
                        <ImageButton
                            image={images.Buttons.AppleDownload}
                            style={{ width: 150, height: 44, marginLeft: 8}}
                            onPress={() => handleLinksPress('Apple')}
                        />
                        <ImageButton
                            image={images.Buttons.GoogleDownload}
                            style={{ width: 150, height: 44, marginRight: 8 }}
                            onPress={() => handleLinksPress('Google')}
                        />
                    </SimpleContainer>
                    <SimpleImage src={images.Backgrounds.LongLogoBack} style={{height: '40px', justifyContent: 'center', marginTop:32}}/>
                    <SimpleImage src={images.Phones.Page1Phone} style={{width:'90%', marginTop:18}}/>

                </SimpleContainer>
            ) : (
                <SimpleContainer style={styles.textContainerBig}>
                    <TextMedium80 style={{ textAlign: 'center', whiteSpace: 'pre-line'}}>
                        {`פשוט לקבל החלטות פיננסיות`}
                    </TextMedium80>
                    <SimpleContainer style={{alignItems:'center'}}>
                    <TextWithImageBackGround imageUrl={images.Backgrounds.Circle} style={{width:250,height:115}}><TextMedium80>טובות</TextMedium80></TextWithImageBackGround>
                        <TextMedium80 style={{ textAlign: 'center', whiteSpace: 'pre-line'}}>
                            {`יותר`}
                        </TextMedium80>
                    </SimpleContainer>
                    <TextMedium24 style={{ textAlign: 'center', marginTop: 32, whiteSpace: 'pre-line'}}>
                        {`FolloApp מרכזת את כל הפעילות הפיננסית שלך, מפיקה עבורך תובנות\nוהמלצות מותאמות אישית ומוציאה לפועל כל החלטה שלך`}
                    </TextMedium24>
                    <Text16 style={{ textAlign: 'center', marginTop: 32 }}>
                        להורדת האפליקציה:
                    </Text16>
                    <SimpleContainer style={styles.buttonContainerBig}>
                        <ImageButton
                            image={images.Buttons.AppleDownload}
                            style={{ width: 150, height: 44, marginLeft: 8}}
                            onPress={() => handleLinksPress('Apple')}

                        />
                        <ImageButton
                            image={images.Buttons.GoogleDownload}
                            style={{ width: 150, height: 44, marginRight: 8 }}
                            onPress={() => handleLinksPress('Google')}

                        />
                    </SimpleContainer>
                    <SimpleImage src={images.Phones.Page1Phone} style={{width: '518px', height: '637px', paddingTop:32}}/>
                </SimpleContainer>
            )}
        </SimpleContainer>
    );
});


const styles = {
    container: (isSmallScreen) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: isSmallScreen ? 90 : 120,
        backgroundImage: isSmallScreen ? `url(${images.Backgrounds.SmallPage1Back})` : `url(${images.Backgrounds.LoginPage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        flex:1
    }),
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center',
    },
    textContainerBig: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex:1
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 16,
    },
    buttonContainerBig: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 16,
    },
};

export default Page1;
