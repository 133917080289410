import React from 'react';
import { SimpleContainer } from './SimpleContainer';
import SimpleButton from './SimpleButton';
import ImageButton from './ImageButton';
import { icons } from '../../assets/icons/icons';
import { colors } from '../../styles/colors';


export default function SimplePopup({ isOpen, closePopupFunction, style, children }) {
    if (!closePopupFunction) {
        throw new Error("closePopupFunction is required");
    }

    const popupOverlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000, // ensures the popup is above other content
    };

    const XimageStyle = {
        position: 'fixed',
        top: 60,
        left: 20,
        right: 20,
        width: 28,
        height: 28,
        backgroundColor: colors.gray, // semi-transparent background
        borderRadius: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1002, // ensures the popup is above other content
    };

    const popupContentStyle = {
        padding: '20px',
        borderRadius: '10px',
        width: '100%',
        zIndex: 1001, // ensures the popup content is above the overlay
        ...style,
    };

    return (
        isOpen ? (
            <SimpleContainer style={popupOverlayStyle} onClick={closePopupFunction}>

                <SimpleContainer
                    style={popupContentStyle}
                    onClick={(e) => e.stopPropagation()} // Prevents the click event from bubbling up to the overlay
                >
                    {children}
                </SimpleContainer>
            </SimpleContainer>
        ) : null
    );
}
