import { buttonSizes } from "../../../styles/buttons/buttonSizes";
import { SimpleContainer } from "../../simpleComponents/SimpleContainer";
import PrimaryButton from "../../styledComponents/buttons/generic/PrimaryButton";
import SecondaryButton from "../../styledComponents/buttons/generic/SecondaryButton";


export default function LoginButtons({LoginButtons, style})
{
    const handleClick = () => {
        window.location.href = 'https://mngp.folloapp.co.il/Login.aspx?ReturnUrl=%2f';
    };

    return (
        <SimpleContainer style={style}>
            <SecondaryButton
                size={buttonSizes.MEDIUM}
                onPress={handleClick}
            >
                {LoginButtons.Agent.text}
            </SecondaryButton>
            {/* <SimpleContainer style={{marginRight:8}}>
                <PrimaryButton
                    size={buttonSizes.MEDIUM}
                >
                    {LoginButtons.Users.text}
                </PrimaryButton>
            </SimpleContainer> */}
        </SimpleContainer>
    );
}