import React from 'react';

export default function SimpleImage({ src, style, tintColor, resizeMode = 'contain', ...props }) {
    const imageStyle = {
        ...style,
        objectFit: resizeMode,
        filter: tintColor ? `brightness(0) saturate(100%) invert(${tintColor})` : undefined,
        display: 'block',  // Ensure the image is a block element to avoid extra space from inline elements
        maxHeight: '100%',  // Ensure the image doesn't exceed its container height
    };

    return (
        <img
            {...props}
            src={src}
            style={imageStyle}
            alt="SimpleImage"  // Ensure to add a meaningful alt text
        />
    );
}
