import './css/style.css'
import MainScreen from "./pages/mainScreen/MainScreen";
import PrivacyScreen, { PrivacyScreenName } from './pages/mainScreen/MainScreenPrivacy';
import ContactScreen, { ContactScreenName } from './pages/mainScreen/termsAndConditions copy/Contact';
import TermsOfConditionScreen, { TermsOfConditionScreenName } from './pages/mainScreen/termsAndConditions/TermsOfConditionScreen';
import { Routes, Route, Navigate } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainScreen />} />
      <Route path={TermsOfConditionScreenName} element={<TermsOfConditionScreen />} />
      <Route path={ContactScreenName} element={<ContactScreen />} />
      <Route path={PrivacyScreenName} element={<PrivacyScreen />} />
      <Route path="/*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}

export default App;
