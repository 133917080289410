import React from 'react';
import { SimpleContainer } from '../simpleComponents/SimpleContainer';
import { colors } from '../../styles/colors';

export default function LightCard({ children, style }){
    const cardStyle = {
        ...styles.card,
        ...style,
    };

    return (
        <SimpleContainer style={cardStyle}>
            {children}
        </SimpleContainer>
    );
};

const styles = {
    card: {
        backgroundColor: colors.white,
        borderRadius: 16,
        border: '1px solid var(--Border-UI-border, #E5EAFF)',
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
        width: '100%', // Ensure it takes the full width
        alignSelf: 'stretch',
        elevation: 4,
        shadowColor: colors.primary,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        zIndex: 1, // Ensure elevation works on web
        maxWidth: 600,
    }
};