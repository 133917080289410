import React from 'react';
import { createRoot } from 'react-dom/client'; // Import from "react-dom/client"
import './css/style.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import NativeLikeDialogManager from './providers/NativeLikeDialogManger';

createRoot(document.getElementById('root')).render(
  <NativeLikeDialogManager>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </NativeLikeDialogManager>

);

reportWebVitals();
