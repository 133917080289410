import React from 'react';
import { useMediaQuery } from "react-responsive";
import { SimpleContainer } from "../../../components/simpleComponents/SimpleContainer";
import ExpandableCard from "../components/ExpandableCard";
import { MainScreenData } from "../MainScreenData";
import { Text24 } from "../../../components/styledComponents/texts/Text24";
import { Text60 } from "../../../components/styledComponents/texts/Text60";

const { Page6Data } = MainScreenData;

const Page6 = React.forwardRef((props, ref) => {
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });

    return (
        <SimpleContainer ref={ref} style={styles.container(isSmallScreen)}>
            <SimpleContainer style={styles.ExpandableCardContainer}>
                {isSmallScreen ? <Text24>שאלות תשובות</Text24> : <Text60>שאלות תשובות</Text60>}
                <SimpleContainer style={{ paddingTop: isSmallScreen ? 24 : 72, display: 'flex', flexDirection: 'column' }}>
                    {Page6Data.map((item, index) => (
                        <ExpandableCard key={index} label={item.title} style={{ marginTop: index !== 0 ? 8 : 0 }}>
                            {item.content}
                        </ExpandableCard>
                    ))}
                </SimpleContainer>
            </SimpleContainer>
        </SimpleContainer>
    );
});

const styles = {
    container: (isSmallScreen) => ({
        paddingLeft: isSmallScreen ? 20 : 160,
        paddingRight: isSmallScreen ? 20 : 160,
        paddingTop: isSmallScreen ? 40 : 96, // Adjust based on the height of the header
        paddingBottom: 40,
        background: '#F7F8FF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden', // Prevent horizontal overflow
        flex:1
    }),
    ExpandableCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
};

export default Page6;
