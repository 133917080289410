import React from 'react';
import { SimpleContainer } from '../simpleComponents/SimpleContainer';
import { images } from '../../assets/images/images';
import SimpleImage from '../simpleComponents/SimpleImage';
import HeaderLinksList from '../../components/header/components/HeaderLinksList';
import LoginButtons from './components/LoginButtons';
import { useMediaQuery } from 'react-responsive';
import LeftToRightDrawer from './components/LeftToRightDrawer';
import { MainScreenData } from '../../pages/mainScreen/MainScreenData';

const { Links, Login } = MainScreenData;

function Header({ HandleScrollToPages }) {
    const isSmallScreen = useMediaQuery({ maxWidth: 850 });

    return (
        <SimpleContainer style={styles.container(isSmallScreen)}>
            <SimpleImage
                src={images.Logos.FolloApp}
                height={40}
                width={150}
                style={styles.logo(isSmallScreen)}
            />

            {isSmallScreen ? (
                <LeftToRightDrawer HandleScrollToPages={HandleScrollToPages} />
            ) : (
                <SimpleContainer style={styles.navContainer}>
                    <HeaderLinksList ListOfButtons={Links} HandleScrollToPages={HandleScrollToPages} />
                    <LoginButtons LoginButtons={Login} style={styles.LoginButton} />
                </SimpleContainer>
            )}
        </SimpleContainer>
    );
}

const styles = {
    container: (isSmallScreen) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the background color and transparency
        backdropFilter: 'blur(10px)', // Apply a blur effect to the background
        WebkitBackdropFilter: 'blur(10px)', // For Safari support
        zIndex: 1000,
        padding: '10px 0',
        justifyContent: 'space-between',
        paddingLeft: isSmallScreen ? 10 : 20,
        paddingRight: isSmallScreen ? 10 : 20,
        minHeight: 60
    }),
    logo: (isSmallScreen) => ({
        position: isSmallScreen ? null : 'absolute',
    }),
    navContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    LoginButton: {
        position: 'absolute',
        left: 20
    }
};

export default Header;
