export const icons = {
    //NavBar
    Menu: require('./NavBar/Menu.png'),
    backButton: require('./NavBar/backButton.png'),

    //MainScreen
    GreenV: require('./MainScreen/GreenV.png'),
    LeftArrow: require('./MainScreen/LeftArrow.png'),
    DownArrow: require('./MainScreen/DownArrow.png'),

    x: require('./x.png')
}