import { useState } from "react";
import LightCard from "../../../components/Containers/LightCard";
import ImageButton from "../../../components/simpleComponents/ImageButton";
import { icons } from "../../../assets/icons/icons";
import { SimpleContainer } from "../../../components/simpleComponents/SimpleContainer";
import TextMedium20 from "../../../components/styledComponents/texts/TextMedium20";
import { Text16 } from "../../../components/styledComponents/texts/Text16";

export default function ExpandableCard({label, children, style})
{
    const [isPressed, setIsPressed] = useState()

    function HandlePress() {
        setIsPressed(!isPressed)
    }

    return (
        <LightCard style={style}>
            <SimpleContainer>
                <ImageButton image={isPressed ? icons.DownArrow : icons.LeftArrow} onPress={HandlePress} imageStyle={styles.IconStyle}/>
                <TextMedium20 style={{marginRight:16}}>{label}</TextMedium20>
            </SimpleContainer>
            {isPressed && 
                <Text16 style={{marginRight:48}}>{children}</Text16>
            }
        </LightCard>
    );
}

const styles = { 
    IconStyle: {
        width:32,
        height:32
    }
}