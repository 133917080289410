import { images } from "../../assets/images/images";

export const MainScreenData = {
    Links: [
        {
            text: "השירותים שלנו",
            onPress: null,
            pageName: "OurServices"
        },

        {
            text: "תובנות והמלצות",
            onPress: null,
            pageName: "InsightAndRecommendetion"
        },
        {
            text: "למה אנחנו?",
            onPress: null,
            pageName: "WhyFolloApp"
        },
        {
            text: "שאלות ותשובות",
            onPress: null,
            pageName: "QuestionsAndAnswer"
        },
    ],
    Login: {
        Agent: {
            text: "כניסת סוכנים",
            onPress: null
        },
        Users: {
            text: "כניסת משתמשים",
            onPress: null
        },
    },
    Page3: [
        {
            title: 'חסכון במשכנתא',
            content: 'ייעוץ לבחירת משכנתא, שיפור התנאים במשכנתא קיימת, מחזור משכנתא ועוד',
            image: images.IconImages.MortagageSaving,
            height: 160
        },
        {
            title: 'השקעה חכמה',
            content: 'התאמה אישית של מוצרי חסכון והשקעה: פקדונות, תיקי השקעות, פוליסות חסכון וקרנות גמל להשקעה',
            image: images.IconImages.SmartInvestment,
            height: 160
        },
        {
            title: 'תכנון ובקרה פיננסית',
            content: 'לתכנן תקציב, יעדים משפחתיים, הכנסות והוצאות חודשיות ולנהל טוב יותר את התזרים',
            image: images.IconImages.FinancialPlanning,
            height: 160
        },
        {
            title: 'החזרי מס',
            content: 'לקבל את החזרי המס שמגיעים לך, בתהליך פשוט וקצר',
            image: images.IconImages.TaxReturn,
            height: 160
        },
        {
            title: 'טיפול במוצרים פנסיונים',
            content: 'בחירה של מסלולי קרן הפנסיה וביטוח המנהלים המתאימים ביותר עבורך',
            image: images.IconImages.PensionProduct,
            height: 160
        },
        {
            title: 'חסכון בביטוח',
            content: 'ליהנות מליווי לבחירת ביטוח שייתן מענה מיטבי לצרכים שלך, בתנאים הטובים ביותר',
            image: images.IconImages.InsuranceSaving,
            height: 160
        },
    ],
    Page5Data: [
        {
            title: '360 מעלות',
            content: 'לקבל תמונת מצב מלאה ועדכנית על הפעילות הפיננסית שלך',
        },
        {
            title: 'מותאם אישית',
            content: 'ליהנות מתובנות והמלצות שהותאמו אישית עבורך',
        },
        {
            title: 'שמים אותך במרכז',
            content: 'העובדה שמדובר בגוף עצמאי מאפשרת לנו לדאוג רק לאינטרסים שלך',
        },
        {
            title: 'חדשני',
            content: 'מערכת חכמה שמנתחת את הפעילות הפיננסית שלך, עוקבת אחר השינויים והמגמות בשוק, מפיקה תובנות וממליצה על צעדים נדרשים',
        },
        {
            title: 'פשוט ויעיל ',
            content: 'ממשק נגיש ונוח שמאפשר לך להתעדכן, להתייעץ ולקבל החלטות מושכלות - בלחיצת כפתור',
        },
        {
            title: '100% מוגן',
            content: 'פיקוח וניטור שוטפים ע”י רשות ני”ע, בנק ישראל ורשות שוק ההון, מאפשרים לנו לעמוד בתנאים מחמירים של אבטחת מידע ושמירת נתונים',
        },
    ],
    Page6Data: [
        {
            title: 'באלו תחומים אתם יכולים לסייע לי?',
            content: 'ריכוז המידע הפיננסי שלך בצורה מסודרת במקום אחד, מתן התראות חשובות לייעול  ההתנהלות הפיננסית ואפשרות להתייעצות עם מומחים להשגת היעדים הכלכליים הרצויים ויישומן.',
        },
        {
            title: 'אלו סוגי התראות אני עשוי לקבל?',
            content: 'ליהנות מתובנות והמלצות שהותאמו אישית עבורך',
        },
        {
            title: 'איזו אינפורמציה אתם אוספים ממני?',
            content: 'העובדה שמדובר בגוף עצמאי מאפשרת לנו לדאוג רק לאינטרסים שלך',
        },
        {
            title: 'לשם מה אתם אוספים אינפורמציה זו?',
            content: 'מערכת חכמה שמנתחת את הפעילות הפיננסית שלך, עוקבת אחר השינויים והמגמות בשוק, מפיקה תובנות וממליצה על צעדים נדרשים',
        },
        {
            title: 'האם אני יכול לקבל אינפורמציה בלי לתת לכם אישור לאיסוף מידע?',
            content: 'ממשק נגיש ונוח שמאפשר לך להתעדכן, להתייעץ ולקבל החלטות מושכלות - בלחיצת כפתור',
        },
        {
            title: 'תחת איזה פיקוח אתם נמצאים?',
            content: 'פיקוח וניטור שוטפים ע”י רשות ני”ע, בנק ישראל ורשות שוק ההון, מאפשרים לנו לעמוד בתנאים מחמירים של אבטחת מידע ושמירת נתונים',
        },
        {
            title: 'מה אתם מקבלים בתמורה לשירות שאתם מציעים?',
            content: 'פיקוח וניטור שוטפים ע”י רשות ני”ע, בנק ישראל ורשות שוק ההון, מאפשרים לנו לעמוד בתנאים מחמירים של אבטחת מידע ושמירת נתונים',
        },
        {
            title: 'האם המידע שלי מועבר לגורם שלישי?',
            content: 'פיקוח וניטור שוטפים ע”י רשות ני”ע, בנק ישראל ורשות שוק ההון, מאפשרים לנו לעמוד בתנאים מחמירים של אבטחת מידע ושמירת נתונים',
        },
        {
            title: 'מה קורה למידע שלי אם אני מחליט להסיר את האפליקציה?',
            content: 'פיקוח וניטור שוטפים ע”י רשות ני”ע, בנק ישראל ורשות שוק ההון, מאפשרים לנו לעמוד בתנאים מחמירים של אבטחת מידע ושמירת נתונים',
        },
        {
            title: 'האם הנתונים מתעדכנים מיידית ברגע שנתתי הרשאה לאיסוף נתונים?',
            content: 'פיקוח וניטור שוטפים ע”י רשות ני”ע, בנק ישראל ורשות שוק ההון, מאפשרים לנו לעמוד בתנאים מחמירים של אבטחת מידע ושמירת נתונים',
        },
        {
            title: 'אפשר לצרף בן משפחה נוסף לחשבון שלי?',
            content: 'פיקוח וניטור שוטפים ע”י רשות ני”ע, בנק ישראל ורשות שוק ההון, מאפשרים לנו לעמוד בתנאים מחמירים של אבטחת מידע ושמירת נתונים',
        },
        {
            title: 'איך המידע שלי נשמר במערכת?',
            content: 'פיקוח וניטור שוטפים ע”י רשות ני”ע, בנק ישראל ורשות שוק ההון, מאפשרים לנו לעמוד בתנאים מחמירים של אבטחת מידע ושמירת נתונים',
        },
    ],
    FooterData: [
        {
            text: 'מי אנחנו?',
            pageName: "WhoWeAre"
        },

        {
            text: 'למה אנחנו?',
            pageName: "WhyFolloApp"
        },

        {
            text: 'תנאי שימוש',
            pageName: "TermsOfConditions"
        },

        {
            text: 'יצירת קשר',
            pageName: "Contact"
        },
        {
            text: 'השירותים שלנו',
            pageName: "OurServices"
        },

        {
            text: 'מדיניות הפרטיות',
            pageName: "PrivacyTerms"
        },
    ]
}