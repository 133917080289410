import React from 'react';
import LiroyText from './LiroyText';
import { defaultFont } from '../../../assets/fonts/defaultFont';

export default function TextMedium16({ children, ...props }) {
    return (
        <LiroyText
            {...props}
            size={16}
            fontWeight={defaultFont.medium}
        >
            {children}
        </LiroyText>
    );
}