import React from 'react';
import { SimpleContainer } from './SimpleContainer';
import SimpleImage from './SimpleImage';

export default function Screen({ children, imageBackgroundSource, style }) {
    const screenStyle = {
        ...(process.env.NODE_ENV !== 'production' && { backgroundColor: 'red' }), // this line is here so we won't forget to put the right background
        ...styles.screen,
    };

    const childrenContainerStyle = {
        ...style,
        ...styles.childrenContainer,
    };

    return (
        <SimpleContainer style={screenStyle}>
            {imageBackgroundSource && (
                <SimpleImage
                    src={imageBackgroundSource}
                    style={styles.backgroundImage}
                />
            )}
            <SimpleContainer style={childrenContainerStyle}>
                {children}
            </SimpleContainer>
        </SimpleContainer>
    );
}

const styles = {
    backgroundImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        objectFit: 'cover',
    },
    screen: {
        width: '100%',
        height: '100%',
    },
    childrenContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        zIndex: 1,
        flexDirection: 'column',
    },
};
