import { useState } from 'react';
import { colors } from '../../styles/colors'; // Adjust the path as necessary

export default function ImageButton({
    image,
    tintColor,
    disabledTintColor,
    imageStyle,
    disabled,
    onPress,
    onPressIn,
    onPressOut,
    style,
    ...props
}) {
    const [isPressed, setIsPressed] = useState(false);

    function isPressable() {
        return !disabled;
    }

    function handlePressIn(event) {
        if (isPressable()) {
            setIsPressed(true);
            onPressIn?.(event);
        }
    }

    function handlePressOut(event) {
        if (isPressable()) {
            setIsPressed(false);
            onPressOut?.(event);
        }
    }

    function handlePress(event) {
        if (isPressable()) {
            setIsPressed(false);
            onPress?.(event);
        }
    }

    function getFilter() {
        if (disabled && disabledTintColor) {
            return disabledTintColor === colors.white ? 'brightness(0) invert(1)' : 'none';
        }
        return tintColor === colors.white ? 'brightness(0) invert(1)' : 'none';
    }

    const buttonStyle = {
        ...style,
        opacity: isPressed ? 0.5 : 1,
        border: 'none',
        background: 'none',
        padding: 0,
        cursor: disabled ? 'not-allowed' : 'pointer',
    };

    const imgStyle = {
        width: '100%',  // Ensure the image takes full width of the button
        height: '100%', // Ensure the image takes full height of the button
        ...imageStyle,
        filter: getFilter(),
    };

    return (
        <button
            {...props}
            onMouseDown={handlePressIn}
            onMouseUp={handlePressOut}
            onMouseLeave={handlePressOut}
            onClick={handlePress}
            disabled={disabled}
            style={buttonStyle}
        >
            <img src={image} alt="" style={imgStyle} />
        </button>
    );
}
