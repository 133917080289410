import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNativeLikeDialogManager } from "../../../providers/NativeLikeDialogManger";
import { DialogOptionsDirection } from "../../../components/dialogs/NativeLikeDialog";
import { termsAndConditionsScreenData } from "../components/termsAndConditonsData";
import Screen from "../../../components/simpleComponents/Screen";
import Header from "../../../components/header/Header";
import Page1 from "../Page1/Page1";
import Page2 from "../Page2/Page2";
import Page3 from "../Page3/Page3";
import Page4 from "../Page4/Page4";
import { SimpleContainer } from "../../../components/simpleComponents/SimpleContainer";
import SimpleImage from "../../../components/simpleComponents/SimpleImage";
import Page5 from "../Page5/Page5";
import Page6 from "../Page6/Page6";
import Footer from "../../../components/footer/Footer";
import { images } from "../../../assets/images/images";
import SimpleDialog from "../../../components/simpleComponents/SimpleDialog";
import SimpleText from "../../../components/simpleComponents/SimpleText";
import Separator from "../../../components/specializedComponents/separator/Separator";
import PrimaryTextInput from "../termsAndConditions copy/components/PrimaryTextInput";
import PrimaryButton from "../../../components/styledComponents/buttons/generic/PrimaryButton";
import { androidColors } from "../../../styles/nativeLikeColors/androidColors";
import { colors } from "../../../styles/colors";
import { defaultFont } from "../../../assets/fonts/defaultFont";
import ImageButton from "../../../components/simpleComponents/ImageButton";
import { icons } from "../../../assets/icons/icons";
import { PrivacyTermsData } from "../components/PrivacyTermsData";

export const TermsOfConditionScreenName = "/TermsOfCondition";

export default function TermsOfConditionScreen() {
    const isSmallScreen = useMediaQuery({ maxWidth: 1280 });
    const WhoWeAre = useRef(null);
    const QuestionsAndAnswer = useRef(null);
    const InsightAndRecommendetion = useRef(null);
    const WhyFolloApp = useRef(null);
    const WhyFolloAppSmall = useRef(null);
    const OurServices = useRef(null);
    const { showDialog } = useNativeLikeDialogManager();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        showDialog(TERMS_DIALOG_PROPS)
    }, [])

    function scrollToElementWithOffset(element, offset = 20) {
        if (element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollPosition = rect.top + scrollTop - offset;
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
    }

    const TERMS_DIALOG_PROPS = {
        controlId: "TermsOfConditions",
        title: `תקנון תנאי שימוש ביישומון ובאתר`,
        subtitle: termsAndConditionsScreenData.content.paragraph,
        options: [{ ButtonText: "סגירה", }],
        getOptionText: option => option.ButtonText,
        getOptionOnPress: option => option.onPress,
        optionsDirection: DialogOptionsDirection.HORIZONTAL,
        EnableScrollView: true
    }


    const CONTACT_DIALOG_PROPS = {
        controlId: "Contact",
        title: `המייל התקבל בהצלחה`,
        subtitle: 'הצוות שלנו יחזור אליכם בהקדם',
        options: [{ ButtonText: "סגירה", }],
        getOptionText: option => option.ButtonText,
        getOptionOnPress: option => option.onPress,
        optionsDirection: DialogOptionsDirection.HORIZONTAL,
    }
    const PRIVACY_DIALOG_PROPS = {
        controlId: "TermsOfConditions",
        title: `מדיניות הפרטיות`,
        subtitle: PrivacyTermsData.content.paragraph,
        options: [{ ButtonText: "סגירה", }],
        getOptionText: option => option.ButtonText,
        getOptionOnPress: option => option.onPress,
        optionsDirection: DialogOptionsDirection.HORIZONTAL,
        EnableScrollView: true
    }
    function HandleScrollToPages(pageName) {
        console.log("PageName", pageName);
        switch (pageName) {
            case "Contact":
            case "ContactSmall":
                setIsDialogOpen(true)
                break;
            case "TermsOfConditions":
            case "TermsOfConditionsSmall":
                showDialog(TERMS_DIALOG_PROPS)
                break;
            case "WhoWeAre":
            case "WhoWeAreSmall":
                if (OurServices.current) {
                    WhoWeAre.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case "OurServices":
                if (OurServices.current) {
                    OurServices.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case "OurServicesSmall":
                scrollToElementWithOffset(OurServices.current);
                break;
            case "PrivacyTerms":
            case "PrivacyTermsSmall":
                showDialog(PRIVACY_DIALOG_PROPS)
                break;
            case "WhyFolloApp":
                if (WhyFolloApp.current) {
                    WhyFolloApp.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case "WhyFolloAppSmall":
                scrollToElementWithOffset(WhyFolloAppSmall.current);
                break;
            case "InsightAndRecommendetion":
                if (InsightAndRecommendetion.current) {
                    InsightAndRecommendetion.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case "InsightAndRecommendetionSmall":
                scrollToElementWithOffset(InsightAndRecommendetion.current);
                break;
            case "QuestionsAndAnswer":
                if (QuestionsAndAnswer.current) {
                    QuestionsAndAnswer.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case "QuestionsAndAnswerSmall":
                scrollToElementWithOffset(QuestionsAndAnswer.current);
                break;
            default:
                break;
        }
    }

    function closeDialogFunction() {
        setIsDialogOpen(false)
    }

    const styles = getAndroidStyles();

    function getAndroidStyles() {
        function isPrimary(index) {
            return index === 0;
        }

        return {
            dialog: {
                backgroundColor: androidColors.background,
                minWidth: '20%',
                maxWidth: "96%",
                maxHeight: '80%',
                borderRadius: 16,
                flexDirection: 'column',
            },
            textsContainer: {
                paddingLeft: 32,
                paddingRight: 32,
                paddingTop: 24,
                paddingBottom: 16,
            },
            label: {
                fontFamily: defaultFont.medium,
                fontSize: 20,
                flex: 1
            },
            paragraph: {
                marginTop: 24,
                marginBottom: 24,
                marginRight: 32,
                marginLeft: 32,
                fontSize: 16,
                color: androidColors.paragraph,
                maxHeight: '736px',
                maxWidth: "90%",
                whiteSpace: 'pre-line'
            },
            buttonsContainer: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: 16,
                marginBottom: 16,
                marginLeft: 16,
            },
            getButtonStyle: (index) => ({
                alignItems: 'center',
                paddingVertical: 10,
                paddingHorizontal: 8,
                marginLeft: 8,
            }),
            getButtonTextStyle: (index) => {
                const isButtonPrimary = isPrimary(index);
                return {
                    ...(isButtonPrimary && { fontFamily: defaultFont.medium }),
                    color: isButtonPrimary ? androidColors.primaryText : colors.text,
                    fontSize: 17,
                    lineHeight: 22,
                };
            }
        };
    }

    function handleOptionPress() {
        setIsDialogOpen(false)
        showDialog(CONTACT_DIALOG_PROPS)
    }

    return (
        <Screen style={{ background: '#fff' }}>
            <Header HandleScrollToPages={HandleScrollToPages} />
            <Page1 ref={WhoWeAre} />
            <Page2 />
            <Page3 ref={OurServices} />
            <Page4 refs={{ InsightAndRecommendetion, WhyFolloApp }} />
            {!isSmallScreen && (
                <SimpleContainer style={{ justifyContent: 'center', display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
                    <SimpleImage src={images.Computer.DesktopComputerBig} resizeMode="center" style={{ width: '100%' }} />
                    <SimpleImage src={images.Backgrounds.LongLogosBackPage4} style={{ width: 769, height: 81, marginTop: 80 }} />
                </SimpleContainer>
            )}
            {isSmallScreen && <Page5 ref={WhyFolloAppSmall} />}
            <SimpleImage src={images.Videos.YouTube} resizeMode="center" style={{ width: '100%', marginTop: isSmallScreen ? 0 : 80 }} />
            <Page6 ref={QuestionsAndAnswer} />
            <Footer HandleScrollToPages={HandleScrollToPages} />

            <SimpleDialog
                isOpen={isDialogOpen}
                closeOnOutsideClick={true}
                closeDialogFunction={closeDialogFunction}
            >
                <SimpleContainer style={styles.dialog}>
                    <SimpleContainer style={styles.textsContainer}>
                        <SimpleText style={styles.label}>
                            יצירת קשר
                        </SimpleText>
                        <ImageButton
                            onPress={() => setIsDialogOpen(false)}
                            image={icons.x}
                            style={{ width: 20, height: 20 }}
                        />
                    </SimpleContainer>

                    <Separator />

                    <SimpleContainer style={{ marginTop: 20, }}>

                        <PrimaryTextInput
                            style={{ flex: 1, paddingRight: 20, marginLeft: 4, height: 68 }}
                            textInputStyle={{ height: 70 }}
                            label={"שם מלא"}
                        />
                        <PrimaryTextInput
                            style={{ flex: 1, paddingLeft: 20, marginRight: 4, height: 68 }}
                            textInputStyle={{ height: 70 }}
                            label={"אימייל"}
                        />
                    </SimpleContainer>

                    <SimpleContainer style={{}}>
                        <PrimaryTextInput
                            style={{ flex: 1, paddingRight: 20, paddingLeft: 20, marginTop: 12, marginBottom: 20 }}
                            label={"לכל בקשה הזן כאן"}
                        />
                    </SimpleContainer>


                    <Separator />
                    <SimpleContainer style={styles.buttonsContainer}>
                        <PrimaryButton
                            style={styles.getButtonStyle(0)}
                            onPress={() => handleOptionPress()}
                        >
                            שליחה
                        </PrimaryButton>
                    </SimpleContainer>
                </SimpleContainer>
            </SimpleDialog>
        </Screen>
    );
}

const styles = {
    logoTextContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    text: {
        paddingRight: 8
    },

    termsLabel: {
        paddingTop: 8
    },

    termsParagraph: {
        paddingTop: 8
    },

    container: {
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: 40
    },

}
