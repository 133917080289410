import React from 'react';
import SimpleButton from '../../simpleComponents/SimpleButton';
import SimpleIcon from '../../simpleComponents/SimpleIcon';
import SimpleText from '../../simpleComponents/SimpleText';

export default function TextButtonWithTwoOptionalIcons({
    children,
    style,
    textStyle,
    leftIcon,
    leftIconSize,
    leftIconTintColor,
    rightIcon,
    rightIconSize,
    rightIconTintColor,
    ...props
}) {
    const buttonStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...style,
    };

    return (
        <SimpleButton
            {...props}
            style={buttonStyle}
        >
            {leftIcon && (
                <SimpleIcon
                    source={leftIcon}
                    size={leftIconSize}
                    tintColor={leftIconTintColor}
                />
            )}
            <SimpleText style={textStyle}>
                {children}
            </SimpleText>
            {rightIcon && (
                <SimpleIcon
                    source={rightIcon}
                    size={rightIconSize}
                    tintColor={rightIconTintColor}
                />
            )}
        </SimpleButton>
    );
}
