export const PrivacyTermsData = {
    title: "מאשרים את תנאי השימוש וסיימנו",
    subtitle: "כדי לאשר את תנאי השימוש יש לגלול עד מטה",
    agreeToTermsButtonText: "אישור ומעבר להתחברות",
    content:
    {
        label: "מדיניות הפרטיות",
        paragraph: `1.	הגלישה והשימוש באתר זה ו/או האפליקציה שמעמידה החברה ללקוחותיה מעת לעת ו/או כל תשתית מיכונית אחרת אחרת , על השירותים השונים אשר יינתנו בהם ו/או באמצעותם מפעם לפעם (להלן "האתר") הינם בכפוף למדיניות הפרטיות המפורטת להלן. שימוש באתר מהווה הסכמה למדיניות הפרטיות והתחייבות לפעול לפיה. בכל מקרה בו מופיעה המילה "החברה" הכוונה הינה לפולואפ פיננס 2021 בע"מ.
2.	אנא הקפד לעיין ולהתעדכן במדיניות הפרטיות להלן מאחר שעצם השימוש של כל אדם ו/או גוף הגולש ו/או צופה ו/או עושה שימוש בכל דרך אחרת, במישרין או בעקיפין באתר, לרבות בכל תוכן ו/או שירות בו, מהווים הסכמה לאמור במדיניות הפרטיות, כפי שתתעדכן מעת לעת, ללא כל הגבלה או הסתייגות, בהתאם לשיקול דעתה המלא והבלעדי של החברה. אשר על כן, טרם ביצוע פעולה כלשהי באתר, יש לקרוא בעיון רב את תנאי השימוש ומדיניות הפרטיות.
3.	חל איסור לעשות באתר ו/או בכל חלק ממנו שימוש למטרה שאיננה לצורך אישי או בלתי מסחרי.
4.	מובהר בזאת, השימוש בלשון זכר במדיניות הפרטיות, נעשה מטעמי נוחות בלבד, ואין בו כדי לפגוע ו/או ליצור אפליה כלשהי.
5.	החברה מכבדת את פרטיות המשתמשים באתר זה ולשם כך נוקטת באמצעי אבטחת מידע קפדניים בהתאם להוראות הדין החל, ובפרט בהתאם לחוק הגנת הפרטיות תשמ"א-1981 והתקנות מכוחו. מטרת התנאים המפורטים במדיניות פרטיות זו, המהווים חלק בלתי נפרד מתנאי השימוש, הינה לסקור, בין היתר, את האופן בו משתמשת החברה במידע שנמסר לה על-ידי המשתמשים באתר ו/או נאסף על-ידה בעת השימוש באתר וכן את האופן בו מבצע המשתמש שימוש בתוכן המצוי באתר.
6.	חלק מהשירותים באתר טעונים מסירת מידע אישי. במסגרת הרשמה ו/או שימוש באתר ייתכן כי תידרש למסור מידע אישי, כגון: שם, ת.ז., כתובת, מספר טלפון, כתובת דואר אלקטרוני וכיו"ב, כאשר שדות החובה יסומנו במפורש. ככל שלא יימסרו הנתונים המבוקשים בשדות החובה, לא תוכל להירשם לאתר ו/או ליהנות מחלק מהשירותים המוצעים בו. אולם יובהר, כי אין לך כל חובה חוקית למסרם.
7.	לידיעתך, במערכות החברה מתקיים מנגנון אוטומטי לרישום ותיעוד כל פעולות הלקוחות או ניסיונותיהם לביצוע פעולות וזאת על פי דין, לצרכי אבטחת מידע והגנת הפרטיות. מידע זה נשמר במאגרי החברה למשך 24 חודשים או תקופה אחרת שתיקבע בהוראות הדין.
8.	בהשארת פרטיך באתר האינטרנט או בכל אמצעי או פלטפורמה אחרים, הינך מביע את הסכמתך לכך כי הפרטים שמסרת לחברה יישמרו במאגר המידע של החברה שלה וישמשו את החברה לצורך מתן השירותים אליהם נרשמת, וכן לצורך פניה אליך והצעת מוצרים ושירותים לגביהם מסרת את פרטיך, וכן, אלא אם מסרת במפורש כי אינך מעוניין בכך, לצורך פניות נוספות בעתיד בנושאים הנוגעים למוצרים המשווקים על ידי החברה. כמו כן, עשויה העברת המידע להתבצע לצורך עיבוד המידע, מתן שירותי דיוור לחברה והעברת מידע לצורך מתן שירות למוצרים בהתבסס על מכלול המוצרים והשירותים אשר מצויים בידי החברה.
9.	בכפוף להוראות הדין, בהרשמה לאתר הנני מצהיר כי ידוע לי שהמידע נמסר מיוזמתי ובהסכמתי למטרה זו ומרצוני החופשי וכי לא חלה עלי כל חובה שבדין לעשות כן. עם זאת, מילוי חלק מהשדות בהליך הרישום לאתר מהווה תנאי הכרחי לשימוש לסיום הליך ההרשמה.
10.	מבלי לגרוע מהאמור לעיל, בכפוף להסכמתי המפורשת לחברה לקבלת דיוור ישיר ומסרים שיווקיים, תהא החברה רשאית לשלוח לי, מעת לעת, בדואר אלקטרוני ו/או  SMS ו/או באמצעי התקשרות נוספים שמסרתי, מידע בדבר שירותי החברה שלה וכן מידע שיווקי ופרסומי שלה. ידוע לי כי אהיה רשאי לבטל בכל עת את הסכמתי ולחדול מקבלת המידע כאמור, באמצעות השארת פניה בעמוד יצירת הקשר באתר האינטרנט, או באמצעות שליחת הודעה בכתובת דוא"ל  system@folloapp.co.il ,או באמצעות לחצן "הסרה" שיופיע בתחתית ההודעה שתישלח על-ידי החברה וכן אפשרות מענה באותה דרך בה מוצע המשלוח עם המילה "הסר".
11.	ככל ובמסגרת האתר יובאו פרסומות כלשהן שלא מטעם החברה ישירות, מובהר כי הפרסומות הינן באחריות צדדים שלישיים, אשר לחברה לא תהיה כל אחריות בנוגע אליהן, לרבות בכל הנוגע לאיסוף המידע על ידי המפרסמים האמורים ויוחלו על המשתמש הוראות תנאי השימוש של המפרסמים, לרבות בכל הנוגע למידע הנאסף ולהוראות הגנת הפרטיות והמשתמש יוכל לפעול למול הצדדים השלישיים ככל וימצא לנכון והוא מוותר בזאת על כל תביעה ו/או דרישה ו/או טענה בקשר לצדדים שלישיים אלו כנגד החברה.
12.	המשתמש מצהיר כי כל המידע שימסור ו/או יעדכן באתר, הינו נכון, אמין ומדויק וכן, כי ביצע את ההרשמה ו/או מסר את המידע בשמו ועבור עצמו בלבד ולא בשם ו/או עבור צדדים שלישיים, למעט במקרים בהם הורשה לעשות כן, במפורש. המידע שמסר המשתמש, אף במצב אורח, וכן מידע נוסף שיצטבר אודותיו ופעולותיו באתר ישמרו במאגרי המידע של החברה כמפורט לעיל, ויעשה בהם שימוש בהתאם למטרות המאגר המפורטות בסעיף ‎‎8 לעיל ובהתאם למדיניות פרטיות זו ובהתאם להוראות הדין. בכלל זה יעשה שימוש במידע לצורך ניהול השירות, טיפול ומעקב אחר פניות, מעקב אחר טיב השירות, הצעות לקבלת שירותים דומים ו/או משלימים, סטטיסטיקה ושיווק לרבות עיבוד המידע ודיוור ישיר למטרות אלה על-ידי החברה.
13.	החברה רשאית לאסוף וכן לעשות שימוש במידע שנמסר על-ידי המשתמש כאמור במועד ההרשמה, ו/או במידע אשר הצטבר אודות המשתמש אף במצב אורח, לרבות במהלך השימוש באתר, על מנת לשפר, להעשיר או לשנות את האתר, השירותים והתכנים המוצעים בו, וכן על מנת לעדכן את המשתמש ולערוך סקרי שביעות רצון באשר למבצעים ו/או שירותים המוצעים על-ידי החברה ו/או מי מטעמה ו/או על-ידי צדדים שלישיים עמם יש לחברה שיתוף פעולה עסקי. בנוסף, רשאית החברה לעשות שימוש במידע למטרות שיווקיות וסטטיסטיות, ולמסור מידע סטטיסטי לצדדים שלישיים, לרבות אך לא רק מפרסמים, וזאת בכפוף לתנאי מדיניות פרטיות זו. מובהר כי מידע סטטיסטי זה לא יזהה את המשתמש באופן אישי.
14.	ידוע למשתמש כי האתר משתמש בכלים אוטומטיים לצורך אפיון השימוש שלו באתר, שיפור חווית הגלישה ולצרכים שונים של אבטחת המידע והאתר. כלים אלה אוספים מידע אודותיו ואודות השימוש שלו באתר. מידע זה יכול שיכלול, בין היתר, גם את פרטי ספק שירותי האינטרנט שלו, כתובתו בפרוטוקול האינטרנט (כתובת IP), מיקום המכשיר בו נעשה שימוש בגישה לאתר, זמני ואופני השימוש באתר, סוג הדפדפן שלו ופרטים נוספים המעידים על אופן השימוש שעשה באתר, הסכמתו לשימוש במידע לצרכיה של החברה, בדיקות פנימיות וכיו"ב.
15.	החברה אף עשויה לאסוף מידע אודות שימושך באתר כגון: אופן הגעתך לאתר, משך גלישתך באתר, העמודים והתכנים בהם צפית במהלך גלישתך ועוד. 
16.	יובהר, כי איסוף המידע עשוי להתבצע על ידי החברה, אך גם באמצעות ספקים של החברה, באמצעים שונים.
17.	החברה מחוייבת למחיקת מידע שאינו נדרש לצורך מימוש המטרות המפורטות לעיל, ועל כן:
17.1.	כל מידע אודות השימוש במערכת עצמה (פרטי LOG כניסה, יציאה מהאתר והשימוש בו) יישמרו למשך 24 חודשים ו/או תקופה אחרת כפי שיידרש בהוראות הדין.
17.2.	מידע שייאגר במסגרת השימוש באתר, ובכלל זה נתונים פיננסיים שיתקבלו ממקורות שונים וכל מידע אחר, יישמר לתקופה של 10 שנים ממועד סיום ההתקשרות למול הלקוח או תקופה אחרת יותר ככל ומתנהלים הליכים משפטיים, הליכי אכיפה ו/או חקירה המעכבים את מירוץ ההתיישנות על פי כל דין. 
18.	האתר משתמש בעוגיות (להלן "Cookies" ו/או "עוגיות") לצורך תפעולו השוטף והתקין, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש בו, אימות פרטים, כדי להתאים את האתר להעדפות האישיות של המשתמש, לאפיין את המוצרים המתאימים לו, להתאים פרסומות מותאמות עבורו אף בגלישתו באתרים אחרים ולצרכי אבטחת מידע. עוגיות הן קבצי טקסט שהדפדפן של המשתמש יוצר לפי פקודה ממחשביה של החברה. חלק מהעוגיות יפקעו כאשר תסגור את הדפדפן ואחרות נשמרות על גבי הכונן הקשיח במחשב שלך. העוגיות מכילות מידע מגוון כדוגמת הדפים בהם ביקרת, משך הזמן ששהית באתר, מהיכן הגעת אליו, מדורים ומידע שהמשתמש מבקש לראות בעת הכניסה לאתר וכדומה. כמו כן יכול שהעוגיות ישמרו אף מידע בדבר הרגלי הגלישה של המשתמש באתרים אחרים, לרבות האתרים בהם גלש, העמודים באתרים אלה וכל פעולה אחרת בהם. המידע בעוגיות מוצפן, והחברה נוקטת צעדי זהירות על מנת לעשות כל שביכולתה שרק מחשביה ו/או מי מטעמה יוכלו לקרוא ולהבין את המידע האגור בהם.
19.	ככל שאינך רוצה לקבל עוגיות, תוכל להימנע מכך על-ידי שינוי ההגדרות בדפדפן שלך. לשם כך נא היוועץ בקובץ העזרה של הדפדפן. זכור, עם זאת, כי נטרול העוגיות עלול לגרום לכך שלא תוכל להשתמש בחלק מהשירותים המוצעים באתר.
20.	החברה נוקטת באמצעים סבירים ומקובלים על מנת לשמור ולמנוע העברת פרטים אישיים המזהים את המשתמש לצדדים שלישיים, וזאת למעט במקרים כדלקמן:
20.1.1.	בהתאם לדרישת המשתמש ו/או בהסכמתו המפורשת;

20.1.2.	במסגרת שיתופי פעולה עם חברות קשורות עסקית עם החברה;
20.1.3.	בכל מקרה בו המשתמש הפר את הוראות תנאי השימוש ו/או מדיניות הפרטיות;
20.1.4.	בשל צו שיפוטי ו/או דרישה של רשות מוסמכת למסירת המידע;
20.1.5.	בשל כל מחלוקת ו/או טענה ו/או דרישה ו/או תביעה ו/או הליכים משפטיים שיתנהלו בין המשתמש ו/או כל מי מטעמו לבין החברה ו/או מי מטעמה ו/או צד שלישי כלשהו;
20.1.6.	 בכל מקרה בו תסבור החברה כי מסירת המידע נחוצה על מנת למנוע נזק חמור לרכושם ו/או לגופם של החברה ו/או מי מטעמה ו/או משתמשים אחרים ו/או צדדים שלישיים, או על מנת למנוע נזק חמור אחר בהתאם לשיקול דעתה המלא והבלעדי;
20.1.7.	במקרה בו העבירה ו/או המחתה החברה לצד שלישי כלשהו את פעילותה ו/או את זכויותיה וחובותיה כלפי המשתמש, ובלבד שאותו צד שלישי כאמור יקבל על עצמו את ההוראות המפורטות בתנאי השימוש, מדיניות הפרטיות והוראות כל דין.
20.1.8.	מובהר, כי אין בכל האמור לעיל כדי לגרוע מזכותה של החברה להעביר לצדדים שלישים מידע שאינו אישי, אשר אין בו כדי לזהות את המשתמש אישית באופן ישיר או עקיף.
20.2.	יודגש, כי, בכפוף לכל הוראות הדין, המידע הנאסף עשוי להישמר במאגרי מידע של החברה ו/או של צדדים שלישיים הנותנים לה שירותים, המצויים בישראל ובמדינות אחרות ובכלל זה שירותי מחשוב ועבוד נתונים בענן.
20.3.	כן יצוין, כי החברה עושה ו/או עשויה לעשות שימוש באתר בכלים של צדדים שלישיים, כגון מוצרי Google  ו/או Facebook (כגון: Facebook, Google Analytics  וכן ב- Google Tag Manager) לביצוע ניתוחים בדבר השימוש להתאמת הפרסום, כאמור לעיל. 
20.4.	בכלים אלו, או אחרים בהם תעשה החברה שימוש יבוצע ניתוח מידע אנונימי, סטטיסטי ואגרגטיבי אודות הגלישה והפעילות באתר.
20.5.	החברה עשויה לעשות שימוש בכלים לצורך התאמה אישית של מודעות בכלים שונים, כגון Google Signals , למשתמשי Google, שבחשבונם מופעלת האופציה להתאמה אישית של מודעות. ניתן לעיין במדיניות הפרטיות של מוצר זה בקישור:
https://support.google.com/analytics/answer/7532985?hl=iw
20.6.	החברה אף עשויה לעשות שימוש בכלים נוספים כגון AdWords לצורך התאמת הפרסום ברשת האינטרנט, וכן בניתוחים שונים דמוגרפיים ואחרים כגון Google Analytics ובכלים נוספים כגון - Facebook Pixel  לצורך התאמת פרסומות ברשתות החברתיות. 
20.7.	לצורך מתן שירותים אלו עושות החברות המספקות את הכלים והמידע שימוש ב-COOKIES מטעמן, ומידע זה עשוי להישמר במאגרי המידע של חברות אלו מחוץ לישראל. ניתן למצוא מידע אודות אופן השימוש שהחברות עושות ב-COOKIES ואפשרויות העומדות לרשותך לשנות את ההגדרות באתרי החברות עצמן בקישורים הבאים:
https://www.facebook.com/policies/cookies
https://policies.google.com/technologies?hl=en
http://www.google.com/policies/technologies/types/
https://www.google.com/policies/privacy
https://www.outbrain.com/privacy/cookies
https://policies.taboola.com/en/privacy-policy
כמו כן תוכל לעשות שימוש בתוסף ביטול ההסכמה של Google Analytics המיועד להתקנה בדפדפני אינטרנט.
20.8.	החברה עושה ככל שביכולתה כדי להגן על סודיות הנתונים שנמסרים על-ידי המשתמשים באתר ולקוחותיה, תוך נקיטת אמצעי זהירות מקובלים ושימוש בטכנולוגיות אבטחה מתקדמות. ידוע למשתמש, כי החברה מקדישה משאבים ונוקטת אמצעים מחמירים למניעת חדירה לאתר ולסיכול פגיעה אפשרית בפרטיות המשתמשים, לרבות כנגד ניצול מקרי או מכוון, אובדן, הרס או גישה של אנשים בלתי מוסמכים או מורשים, אולם אין ביכולתה למנוע את האמור באופן מוחלט.
20.9.	החברה מבהירה והמשתמש מאשר ומסכים לכך שבמקרים אשר אינם בשליטתה ו/או נובעים מכוח עליון, היא איננה מתחייבת כי האתר יתנהל כסדרו וללא כל הפרעה ו/או כי האתר ו/או הנתונים שנאספו ו/או נמסרו כאמור לעיל יהיו חסינים באופן מוחלט מפני גישה ו/או חדירה בלתי מורשית למאגרי המידע של החברה. עוד ידוע למשתמש כי החברה לא תישא באחריות בגין כל נזק ו/או אובדן, ישירים או עקיפים, מכל סוג שהוא, שנגרמו כתוצאה מכך, לרבות בשל פגיעה בפרטיות.
20.10.	השימוש באתר הינו אישי ופרטי ועל המשתמש חלה החובה לשמור על סודיות שם המשתמש והסיסמא האישיים וכן הקוד הספציפי שניתן לביצוע פעולה. העברת שם המשתמש , הסיסמא ו/או הקוד לאדם אחר, למעט מסירת הקוד למקבל ההעברה, אף אם לא שימשה לצרכים מסחריים, אסורה בהחלט ועלולה לגרום לנקיטת סנקציות והליכים כלפי המשתמשים. מובהר בזאת כי היעדר שמירה על הסיסמאות ו/או הקוד ו/או מכשיר הסלולר בו מותקן היישומון עלולות לגרום לגניבת זהות המשתמש ו/או שימוש ו/או העברת הכספים לצדדים שאינם קשורים לעסקה והחברה לא תישא בכל נזק הקשור למסירת הקוד ו/או הסיסמא לצדדים שלישיים.
20.11.	על המשתמש חלה החובה ליידע את החברה על כל שימוש בלתי מורשה בשם המשתמש והסיסמא האישיים שלו, מיד עם היוודע לו דבר השימוש.
20.12.	במקרה של אובדן או גניבה של המכשיר הנייד או כאשר נודע על גניבת הסיסמה יש להחליף באופן מיידי את הסיסמה.
20.13.	טרם העברת המכשיר הנייד של הלקוח לצד שלישי יש להתנתק מהאתר/יישומון.
20.14.	אין באמור בתנאים אלה כדי לגרוע מתוקפה של כל הסכמה אחרת בין החברה לביני.
20.15.	דפי האתר וכל המידע בו הינם רכושה הפרטי של החברה, הנך רשאי להשתמש במידע למטרות פרטיות ואישיות בלבד. אין להעתיק, לפרסם, להציג בפומבי, להעמיד לרשות הציבור ולהשתמש במידע לכל מטרה אחרת, ובפרט לכל מטרה מסחרית, זכויות הבעלות במידע לרבות זכויות הפצה וכל קניין רוחני אחר במידע הינם בבעלות החברה אלא אם נאמר אחרת. הסימנים והשמות המופיעים באתר הם סימנים ושמות מסחריים קניינים של החברה ואין לעשות בהם כל שימוש שיש בו כדי לפגוע בזכויות הקניין של החברה בסימנים אלה.
20.16.	חברה הזכות לשנות, למחוק ו/או להוסיף על הוראות תנאי השימוש ומדיניות הפרטיות באתר בכל עת, על פי שיקול דעתה הבלעדי, וכל שינוי יחייב את הגולשים ממועד פרסומו באתר, או קבלת הודעה על כך מהחברה בדרך אחרת כלשהי, לפי המוקדם. שימוש באתר לאחר ביצוע השינויים כאמור יעיד על הסכמה לשינויים אלה. ככל והמשתמש אינו מסכים לשינויים, עליו להימנע מהמשך שימוש באתר.
20.17.	נוסח תנאי השימוש ומדיניות הפרטיות המחייב והקובע בכל מועד הינו הנוסח המפורסם באתר. תוקף הוראות תנאי השימוש ומדיניות הפרטיות הינו מצטבר ולא לחילופין והן יפורשו כמתקיימים זה לצד זה ולא מצמצמים זה את תחולת זה.
20.18.	בכל שאלה ו/או הבהרה ו/או תגובה בנוגע למדיניות פרטיות זו או אם ברצונך לעיין לשנות או לתקן את המידע האישי המצוי אצלנו אודותיך, אנא פנה אלינו לדוא"ל system@folloapp.co.il. בכל תכתובת יש לכלול את פרטיך המלאים לרבות כתובת מגורים וכתובת דואר אלקטרוני / טלפון נייד ליצירת קשר. החברה תשתדל להגיב לכל בקשה סבירה תוך זמן סביר.
20.19.	תוכל למצוא מידע נוסף אודות הוראות הדין הישראלי וזכויותיך מכוחו בתחום הגנת הפרטיות באתר הרשות להגנת הפרטיות בכתובת https://www.gov.il/he/Departments/the_privacy_protection_authority
20.20.	יובהר, כי סמכות השיפוט בכל הנוגע למסמך מדיניות זה נתונה לבתי המשפט בישראל, באזור המרכז, בהתאם לסמכות שיפוטם.
20.21.	מפתח היישומון הינו folloappservices.2021@gmail.com ואליו ניתן לפנות בכל סוגיה הנוגעת ליישומון.
20.22.	כמו כן יובהר, כי האמור במדיניות הפרטיות של החברה נכתב בלשון זכר מטעמי נוחות בלבד. 

`,
    },
}