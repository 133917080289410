import React, { createContext, useContext, useMemo, useState, useCallback } from "react";
import NativeLikeDialog from "../components/dialogs/NativeLikeDialog";

const NativeLikeDialogManagerContext = createContext();

export function useNativeLikeDialogManager() {
    return useContext(NativeLikeDialogManagerContext);
}

export default function NativeLikeDialogManager({ children }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogProps, setDialogProps] = useState({});
    const [onDialogCloseFunction, setOnDialogCloseFunction] = useState();

    const closeDialog = useCallback(() => {
        setIsDialogOpen(false);
        onDialogCloseFunction?.();
    }, [onDialogCloseFunction]);

    const value = useMemo(() => {
        function showDialog(dialogProps, onDialogCloseFunction) {
            setDialogProps(dialogProps);
            setIsDialogOpen(true);
            setOnDialogCloseFunction(() => onDialogCloseFunction);
        }

        return { showDialog, closeDialog };
    }, [closeDialog]);

    return (
        <NativeLikeDialogManagerContext.Provider value={value}>
            {children}
            {isDialogOpen && (
                <NativeLikeDialog
                    {...dialogProps}
                    isOpen={isDialogOpen}
                    closeDialogFunction={closeDialog}
                />
            )}
        </NativeLikeDialogManagerContext.Provider>
    );
}
