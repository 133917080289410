import React, { useEffect, useRef, useState } from 'react';
import { colors } from '../../../../styles/colors';
import { SimpleContainer } from './SimpleContainer';
import SimpleButton from '../../../../components/simpleComponents/SimpleButton';
import SimpleIcon from '../../../../components/simpleComponents/SimpleIcon';
import SimpleText from '../../../../components/simpleComponents/SimpleText';
import SimpleTextInput from './SimpleTextInput';
import SupplementalText from './SupplementalText';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';

export default function PrimaryTextInput({
    disabled,
    rightIcon,
    leftIcon,
    label,
    placeholder,
    value,
    onValueChange,
    showErrorsBeforeBlur = false,
    error,
    supplementalText,
    style,
    textInputStyle,
    disableAnalyticsEvents = false,
    ...props
}) {
    const textInputRef = useRef();
    const [isFocused, setIsFocused] = useState(false);
    const [showErrors, setShowErrors] = useState(showErrorsBeforeBlur);

    useEffect(() => {
        setShowErrors(oldValue => oldValue || showErrorsBeforeBlur);
    }, [showErrorsBeforeBlur]);

    function handleFocus() {
        setIsFocused(true);
    }

    function handleBlur() {
        setIsFocused(false);
        setShowErrors(true);
    }

    function getLabelAndLeftIconColor() {
        if (disabled) {
            return colors.disabledHighlighted;
        }
        if (needToShowErrors()) {
            return colors.errorHighlighted;
        }
        return colors.primaryHighlighted;
    }

    function getBorderColor() {
        if (disabled) {
            return colors.disabledHighlighted;
        }
        if (needToShowErrors()) {
            return colors.error;
        }
        if (isFocused) {
            return colors.primaryHighlighted;
        }
        return colors.secondaryHighlighted;
    }

    function getContentAndRightIconColor() {
        if (disabled) {
            return colors.disabledHighlighted;
        }
        return colors.text;
    }

    function focusTextInput() {
        textInputRef.current.focus();
    }

    function needToShowErrors() {
        return showErrors && error;
    }

    const containerStyle = {
        borderRadius: 12,
        paddingTop: 8,
        paddingRight: 8,
        paddingLeft: 8,
        flexDirection: 'row',
        backgroundColor: disabled ? colors.disabled : colors.white,
        borderColor: getBorderColor(),
        borderWidth: disabled || !isFocused ? 1 : 2,
        height: style?.height,
    };

    const labelStyle = {
        fontSize: 12,
        width: '100%',
        textAlign: 'right',
        flexDirection: 'flex-end',
        alignItems: 'flex-start',
        color: getLabelAndLeftIconColor(),
    };

    const contentStyle = {
        fontSize: 16,
        width: '100%',
        color: getContentAndRightIconColor(),
        height: '100%',
        ...textInputStyle,
    };

    const mainContainerStyle = {
        ...style,
    };

    return (
        <SimpleContainer style={mainContainerStyle}>
            <SimpleButton style={containerStyle} onClick={focusTextInput}>
                <SimpleIcon
                    source={leftIcon}
                    size={24}
                    tintColor={getLabelAndLeftIconColor()}
                    style={{ alignSelf: 'center' }}
                />
                <SimpleContainer style={{
                    marginRight: rightIcon ? 12 : 4, marginLeft: leftIcon ? 12 : 4, height: '100%', alignItems: 'flex-start',
                }}>
                    {label && <SimpleText style={labelStyle}>{label}</SimpleText>}
                    <SimpleTextInput
                        ref={textInputRef}
                        disableAnalyticsEvents={disableAnalyticsEvents}
                        placeholder={placeholder}
                        value={value}
                        onValueChange={onValueChange}
                        style={contentStyle}
                        disabled={disabled}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        multiline={true} // Enable multiline input
                        numberOfLines={6} // Suggest a height that fits up to 6 lines
                        {...props}
                    />
                </SimpleContainer>
                <SimpleIcon
                    source={rightIcon}
                    size={24}
                    tintColor={getContentAndRightIconColor()}
                    style={{ alignSelf: 'center' }}
                />
            </SimpleButton>
            <SupplementalText hasError={needToShowErrors()}>
                {needToShowErrors() ? error : supplementalText}
            </SupplementalText>
        </SimpleContainer>
    );
}
