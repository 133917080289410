import React from 'react';
import { icons } from "../../../assets/icons/icons";
import LightCard from "../../../components/Containers/LightCard";
import { SimpleContainer } from "../../../components/simpleComponents/SimpleContainer";
import SimpleIcon from "../../../components/simpleComponents/SimpleIcon";
import { Text16 } from "../../../components/styledComponents/texts/Text16";
import TextMedium20 from "../../../components/styledComponents/texts/TextMedium20";

export default function CardWithLogoTitleAndContent({ ListOfCards, style}) {

    const ContainerStyle = {
        ...style
        ,...styles.container
    }
    
    return (
        <SimpleContainer style={ContainerStyle}>
            {ListOfCards.map((item, index) => (
                <LightCard key={index} style={styles.cardContainer}>
                    <SimpleContainer style={styles.headerContainer}>
                        <SimpleIcon source={icons.GreenV} size={48} />
                        <TextMedium20 style={{fontFamily: 'Fredoka'}}>{item.title}</TextMedium20>
                    </SimpleContainer>
                    <Text16 style={styles.contentContainer}>{item.content}</Text16>
                </LightCard>
            ))}
        </SimpleContainer>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height:'100%',
    },
    cardContainer: {
        width: '100%', // Make sure the card takes full width
        marginTop: 8, // Add some space between cards if needed
        height:'100%',
        minHeight: '184px'
    },
    headerContainer: {
        display: 'flex',
        width:'100%',
        flexDirection: 'row', // Align icon and title to the right
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 16, // Add some space between the icon and the title
    },
    contentContainer: {
        width: '100%', // Take full width
        color: 'var(--Text-Colors-Primary-text, #444C68)', // Text color
        textAlign: 'right', // Text alignment
        fontFamily: 'Fredoka', // Font family
        fontSize: '16px', // Font size
        fontStyle: 'normal', // Font style
        fontWeight: 400, // Font weight
        lineHeight: '20px', // Line height
        display: 'flex', // Set display to flex
        paddingRight: '64px', // Add padding-right
        alignItems: 'flex-end', // Align items to flex-end
        gap: '8px', // Set gap
        alignSelf: 'stretch', // Set align-self to stretch
    }
};
