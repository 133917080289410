import React from 'react';
import { SimpleContainer } from '../simpleComponents/SimpleContainer';
import SimpleText from '../simpleComponents/SimpleText';
import SimpleDialog from '../simpleComponents/SimpleDialog'
import { defaultFont } from '../../assets/fonts/defaultFont';
import { colors } from '../../styles/colors';
import { androidColors } from '../../styles/nativeLikeColors/androidColors';
import { iosColors } from '../../styles/nativeLikeColors/iosColors';
import PrimaryButton from '../styledComponents/buttons/generic/PrimaryButton';
import Separator from '../specializedComponents/separator/Separator';
import SimpleScrollView from '../../pages/mainScreen/termsAndConditions/components/SimpleScrollView';

export const DialogOptionsDirection = Object.freeze({
    VERTICAL: "vertical",
    HORIZONTAL: "horizontal"
});

export default function NativeLikeDialog({
    controlId,
    subtitle,
    title,
    options,
    getOptionText = (option) => option,
    getOptionOnPress,
    optionsDirection = DialogOptionsDirection.VERTICAL,
    isOpen,
    closeDialogFunction,
    closeOnOutsideClick = false,
    EnableScrollView
}) {
    const optionsCount = options.length;

    if (optionsDirection === DialogOptionsDirection.HORIZONTAL && optionsCount > 2) {
        throw Error("NativeLikeDialog in horizontal mode can't have more than 2 options");
    }

    if (!closeDialogFunction) {
        throw new Error("closeDialogFunction is required");
    }

    if (!controlId) {
        throw new Error("controlId is required");
    }

    const styles = getAndroidStyles();

    function isIosPlatform() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    function handleOptionPress(option) {
        if (getOptionOnPress) {
            const onPressFunction = getOptionOnPress?.(option);
            onPressFunction?.();
        }

        closeDialogFunction?.();
    }

    return (
        <SimpleDialog
            controlId={controlId}
            isOpen={isOpen}
            closeOnOutsideClick={closeOnOutsideClick}
            closeDialogFunction={closeDialogFunction}
        >
            <SimpleContainer style={styles.dialog}>
                <SimpleContainer style={styles.textsContainer}>
                    <SimpleText style={styles.label}>
                        {title}
                    </SimpleText>
                </SimpleContainer>

                <Separator />
                {EnableScrollView ? (
                    <SimpleScrollView style={{ justifyContent: 'center', display: 'flex', }}>
                        <SimpleText style={styles.paragraph}>
                            {subtitle}
                        </SimpleText>
                    </SimpleScrollView>
                ) : (
                    <SimpleText style={styles.paragraph}>
                        {subtitle}
                    </SimpleText>
                )}

                <Separator />
                <SimpleContainer style={styles.buttonsContainer}>
                    {options.map((option, index) => (
                        <PrimaryButton
                            key={index}
                            controlId={`${controlId}_option${index + 1}`}
                            style={styles.getButtonStyle(index)}
                            onPress={() => handleOptionPress(option)}
                        >
                            {getOptionText(option)}
                        </PrimaryButton>
                    ))}
                </SimpleContainer>
            </SimpleContainer>
        </SimpleDialog>
    );
}

function getAndroidStyles() {
    function isPrimary(index) {
        return index === 0;
    }

    return {
        dialog: {
            backgroundColor: androidColors.background,
            minWidth: '20%',
            maxWidth: "96%",
            maxHeight: '80%',
            borderRadius: 16,
            flexDirection: 'column',
        },
        textsContainer: {
            paddingLeft: 32,
            paddingRight: 32,
            paddingTop: 24,
            paddingBottom: 16,
        },
        label: {
            fontFamily: defaultFont.medium,
            fontSize: 20,
        },
        paragraph: {
            marginTop: 24,
            marginBottom: 24,
            marginRight: 32,
            marginLeft: 32,
            fontSize: 16,
            color: androidColors.paragraph,
            maxHeight: '736px',
            maxWidth: "90%",
            whiteSpace: 'pre-line'
        },
        buttonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 16,
            marginBottom: 16,
            marginLeft: 16,
        },
        getButtonStyle: (index) => ({
            alignItems: 'center',
            paddingVertical: 10,
            paddingHorizontal: 8,
            marginLeft: 8,
        }),
        getButtonTextStyle: (index) => {
            const isButtonPrimary = isPrimary(index);
            return {
                ...(isButtonPrimary && { fontFamily: defaultFont.medium }),
                color: isButtonPrimary ? androidColors.primaryText : colors.text,
                fontSize: 17,
                lineHeight: 22,
            };
        }
    };
}

function getIosStyles(optionsCount, optionsDirection) {
    function isPrimaryAndHasMoreThanOneOption(index) {
        return isPrimary(index) && hasMoreThanOneOption();
    }

    function isPrimary(index) {
        return index === 0;
    }

    function hasMoreThanOneOption() {
        return optionsCount > 1;
    }

    function isHorizontal() {
        return optionsDirection === DialogOptionsDirection.HORIZONTAL;
    }

    function isVertical() {
        return optionsDirection === DialogOptionsDirection.VERTICAL;
    }

    return {
        dialog: {
            backgroundColor: iosColors.background,
            borderRadius: 14,
            width: 270,
        },
        textsContainer: {
            padding: 16,
            alignItems: 'center',
            borderBottomColor: iosColors.seperator,
            borderBottomWidth: 0.5,
        },
        label: {
            fontFamily: defaultFont.bold,
            fontSize: 17,
            lineHeight: 22,
            textAlign: 'center',
        },
        paragraph: {
            marginTop: 2,
            fontSize: 13,
            lineHeight: 18,
            textAlign: 'center',
        },
        buttonsContainer: {
            display: 'flex',
            flexDirection: isVertical() ? 'column' : 'row-reverse',
        },
        getButtonStyle: (index) => ({
            alignItems: 'center',
            padding: 12,
            ...(isHorizontal() && {
                flex: 1,
                ...(isPrimaryAndHasMoreThanOneOption(index) && {
                    borderLeftColor: 'rgba(60, 60, 67, 0.36)',
                    borderLeftWidth: 0.5,
                }),
            }),
            ...(isVertical() && {
                ...(isPrimaryAndHasMoreThanOneOption(index) && {
                    borderBottomColor: 'rgba(60, 60, 67, 0.36)',
                    borderBottomWidth: 0.5,
                })
            }),
        }),
        getButtonTextStyle: (index) => ({
            color: iosColors.text,
            ...(isPrimary(index) && { fontFamily: defaultFont.bold }),
            fontSize: 17,
            lineHeight: 22,
        }),
    };
}
