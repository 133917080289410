import React, { useRef, useState, useEffect } from "react";

const FAST_SCROLL_THRESHOLD = 15;

const ScrollEvents = Object.freeze({
    SLOW_SCROLL: "slow_scroll",
    FAST_SCROLL: "fast_scroll",
    REACHED_TOP_OF_SCROLL_VIEW: "reached_top_of_scroll_view",
    REACHED_BOTTOM_OF_SCROLL_VIEW: "reached_bottom_of_scroll_view",
});

const SimpleScrollView = React.forwardRef(({
    children,
    shouldScrollToEnd = false,
    onScroll,
    onScrollBeginDrag,
    onScrollToTop,
    onScrollUp,
    onScrollDown,
    onScrollToBottom,
    horizontal = false,
    ...props
}, ref) => {
    const internalScrollRef = useRef();
    const scrollRef = ref || internalScrollRef;

    const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (shouldScrollToEnd && scrollRef.current) {
            scrollToEnd();
        }
    }, [shouldScrollToEnd, scrollRef]);

    function handleOnScrollBeginDrag() {
        setIsDragging(true);
        onScrollBeginDrag?.();
    }

    function handleOnScroll(event) {
        const eventScrollX = event.target.scrollLeft;
        const eventScrollY = event.target.scrollTop;

        detectScrollDirection(eventScrollX, eventScrollY);
        trackScrollToEndAndHandle(eventScrollX, eventScrollY);

        setScrollPos({ x: eventScrollX, y: eventScrollY });
        trackAndSendScrollSpeedEvents(eventScrollX, eventScrollY);

        onScroll?.(event);
    }

    function trackAndSendScrollSpeedEvents(eventScrollX, eventScrollY) {
        const eventName = getScrollSpeedEventName(eventScrollX, eventScrollY);

        const eventParametersMap = new Map();
        eventParametersMap.set("x", eventScrollX);
        eventParametersMap.set("y", eventScrollY);

        // sendEvent(eventName, eventParametersMap); // Uncomment and implement sendEvent if needed
    }

    function getScrollSpeedEventName(eventScrollX, eventScrollY) {
        const scrollSpeed = Math.max(Math.abs(eventScrollX - scrollPos.x), Math.abs(eventScrollY - scrollPos.y));
        return scrollSpeed >= FAST_SCROLL_THRESHOLD ? ScrollEvents.FAST_SCROLL : ScrollEvents.SLOW_SCROLL;
    }

    function trackScrollToEndAndHandle(eventScrollX, eventScrollY) {
        const isAtBottomOfScrollView = eventScrollY >= (scrollRef.current.scrollHeight - scrollRef.current.clientHeight);
        const isAtEndOfScrollView = eventScrollX >= (scrollRef.current.scrollWidth - scrollRef.current.clientWidth);

        if (isAtBottomOfScrollView) {
            handleScrollToBottom();
        }

        if (isAtEndOfScrollView) {
            handleScrollToEnd();
        }
    }

    function trackScrollToTopAndHandle(toX, toY) {
        const isAtTopOfScrollView = toY <= 0;
        const isAtStartOfScrollView = toX <= 0;

        if (isAtTopOfScrollView) {
            handleScrollToTop();
        }

        if (isAtStartOfScrollView) {
            handleScrollToStart();
        }
    }

    function handleScrollToTop() {
        onScrollToTop?.();
    }

    function handleScrollToEnd() {
        onScrollToBottom?.();
    }

    function handleScrollToStart() {
        onScrollToTop?.();
    }

    function detectScrollDirection(eventScrollX, eventScrollY) {
        if (eventScrollX < scrollPos.x) {
            handleScrollLeft(eventScrollX);
        } else if (eventScrollX > scrollPos.x) {
            handleScrollRight(eventScrollX);
        }

        if (eventScrollY < scrollPos.y) {
            handleScrollUp(eventScrollY);
        } else {
            handleScrollDown(eventScrollY);
        }
    }

    function handleScrollLeft(toX) {
        trackScrollToTopAndHandle(toX, scrollPos.y);
        onScrollUp?.(toX);
    }

    function handleScrollRight(toX) {
        onScrollDown?.(toX);
    }

    function handleScrollUp(toY) {
        trackScrollToTopAndHandle(scrollPos.x, toY);
        onScrollUp?.(toY);
    }

    function handleScrollDown(toY) {
        onScrollDown?.(toY);
    }

    function handleScrollToBottom() {
        onScrollToBottom?.();
    }

    function scrollToEnd() {
        if (scrollRef.current) {
            if (horizontal) {
                scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
            } else {
                scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            }
        }
    }

    return (
        <div
            {...props}
            onScroll={handleOnScroll}
            onMouseDown={handleOnScrollBeginDrag}
            onMouseUp={() => setIsDragging(false)}
            ref={scrollRef}
            className="no-scrollbar full-width"
            style={{
                overflowY: horizontal ? 'hidden' : 'auto',
                overflowX: horizontal ? 'auto' : 'hidden',
                whiteSpace: horizontal ? 'nowrap' : 'normal',
                ...props.style,
            }}
        >
            {children}
        </div>
    );
});

export default SimpleScrollView;
