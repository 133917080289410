import React from 'react';
import { colors } from '../../../../styles/colors';
import GenericButton from './GenericButton';

const backgroundColor = {
    normal: colors.primary,
    pressed: colors.primaryHighlighted,
    disabled: colors.disabled,
}

const contentColor = {
    normal: colors.secondary,
    pressed: colors.secondary,
    disabled: colors.disabledHighlighted,
}

export default function PrimaryButton({ children, size, rightIcon, leftIcon, ...props }) {
    const buttonStyle = {
        ...styles.button,
        ...props.style,
    };

    return (
        <GenericButton
            {...props}
            size={size}
            style={buttonStyle}
            rightIcon={rightIcon}
            leftIcon={leftIcon}
            backgroundColor={backgroundColor.normal}
            pressedBackgroundColor={backgroundColor.pressed}
            disabledBackgroundColor={backgroundColor.disabled}
            contentColor={contentColor.normal}
            pressedContentColor={contentColor.pressed}
            disabledContentColor={contentColor.disabled}
        >
            {children}
        </GenericButton>
    );
}

const styles = {
    button: {
        borderRadius: '130px',
        background: colors.primary,
        boxShadow: '0px 6px 25px 0px rgba(80, 100, 222, 0.20)'
    },
};
