import DefaultText from "../../../../components/styledComponents/texts/DefaultText";
import { colors } from "../../../../styles/colors";
import ErrorText from "./ErrorText";


export default function SupplementalText({ children, hasError = false, style }) {
    if (typeof children !== "string") {
        return null;
    }

    const supplementalTextStyle = {
        ...styles.supplementalText,
        ...(hasError && { color: colors.errorHighlighted }),
        ...style,
    };

    if (hasError) {
        return (
            <ErrorText style={supplementalTextStyle}>
                {children}
            </ErrorText>
        );
    }

    return (
        <DefaultText style={supplementalTextStyle}>
            {children}
        </DefaultText>
    );
}

const styles = {
    supplementalText: {
        marginTop: 4,
        paddingRight: 16,
        paddingLeft: 16,
        textAlign: 'right',
    }
};
