import React from "react";
import { defaultFont } from "../../../assets/fonts/defaultFont";
import LiroyText from "./LiroyText";

export function Text24({ children, containerStyle, ...props }) {
    return (
        <LiroyText {...props} fontWeight={defaultFont.regular} size={24}>
            {children}
        </LiroyText>
    );
}
