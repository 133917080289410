export const images = {
    Logos: {
        FolloApp: require('./logos/FolloAppLogo.png'),
        FolloAppWithRights: require('./logos/FolloAppLogoWithRights.png')
    },
    Backgrounds: {
        AppBackground: require('./backgrounds/AppBackground.jpg'),
        LoginPage: require('./backgrounds/HomePage/Login.png'),
        SmallPage1Back: require('./backgrounds/HomePage/SmallPage1Back.png'),
        LongLogoBack: require('./backgrounds/HomePage/LongLogoBack.png'),
        LongLogosBackPage4: require('./backgrounds/Page4/LongLogoBack.png'),
        Circle: require('./backgrounds/HomePage/Circle.png'),
    },
    Buttons: {
        AppleDownload: require('../images/Buttons/btn-apple.png'),
        GoogleDownload: require('../images/Buttons/btn-google.png')
    },
    Phones: {
        Page1Phone: require('../images/Phones/Page1Phone.png'),
        Page2Phone: require('../images/Phones/Page2Phone.png'),
        Page4Phone: require('../images/Phones/Page4Phone.png'),

    },
    IconImages: {
        FinancialPlanning: require('./IconImages/FinancialPlanning.png'),
        MortagageSaving: require('./IconImages/MortagageSaving.png'),
        PensionProduct: require('./IconImages/PensionProduct.png'),
        SmartInvestment: require('./IconImages/SmartInvestment.png'),
        TaxReturn: require('./IconImages/TaxReturn.png'),
        InsuranceSaving: require('./IconImages/InsuranceSaving.png'),
        LightBulb: require('./IconImages/LightBulb.png'),
    },
    Computer: {
        DesktopComputer: require('./Computer/DesktopComputer.png'),
        DesktopComputerBig: require('./Computer/DesktopComputerBig.png'),
    },
    Videos: {
        YouTube: require('./Videos/YouTube.png'),
    }
    
};