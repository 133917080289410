import React from 'react';

export const SimpleContainer = React.forwardRef(({ children, onClick, style, ...props }, ref) => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden', // Ensures the container is unscrollable
        ...style,
    };

    return (
        <div ref={ref} style={containerStyle} onClick={onClick} {...props}>
            {children}
        </div>
    );
});
