export const termsAndConditionsScreenData = {
    title: "מאשרים את תנאי השימוש וסיימנו",
    subtitle: "כדי לאשר את תנאי השימוש יש לגלול עד מטה",
    agreeToTermsButtonText: "אישור ומעבר להתחברות",
    content:
    {
        label: "תקנון ותנאי שימוש ביישומון ובאתר",
        paragraph: `1.	כללי\n
1.1.	השימוש ביישומון ובאתר (להלן יחדיו: "האתר") כפוף לתנאים המפורטים להלן בתקנון תנאי שימוש זה (להלן "תנאי השימוש"; "התקנון"), ומהוה הסכמה מפורשת לתנאים אלו.\n
1.2.	האמור בתנאי השימוש בלשון זכר נועד לנוחות בלבד, ומתייחס לנשים וגברים כאחד. האמור בתנאי השימוש בלשון יחיד כולל אף לשון רבים, ולהיפך, ונכתב כך לשם הנוחות בלבד.\n
1.3.	תנאי השימוש מסדירים את היחסים בין חברת פיננס פולואפ סרוויסס 2021  בע"מ (להלן: "החברה") המפעילה את האתר, לבין כל אדם המשתמש באתר ו/או במידע שהשיג באמצעות האתר ו/או בכל צורה הקשורה לאתר (להלן: "המשתמש" ו/או "הלקוח").\n
1.4.	מטרת החברה הינה לתת ללקוח שירותי יעוץ וליווי כלכלי, לרבות בחינת נכסים ויעוץ פיננסי ראשוני, ליווי הלקוח והמלצות לביצוע שינויים בתיק הפיננסי ושירותים נוספים קשורים, הכל כמפורט בהמשך.\n
1.5.	חלק מהשירותים יבוצעו באמצעות צדדים שלישיים ובמקרה זה יחולו גם תנאי השימוש של אותו צד שלישי, לרבות בנוגע להגנת פרטיות ושימוש במידע. המשתמש נותן הסכמתו כי לא תהיה לו כל טענה כלפי החברה בנוגע לצדדים השלישיים.\n
1.6.	החברה רשאית לעכב או לשלול את רישום המשתמש לאתר בכל עת ובמיוחד עד להשלמת כל הפרטים הנדרשים ע"י החברה. מבלי לגרוע מכל הסעדים המוקנים לחברה על פי דין, החברה זכאית למנוע ממשתמש לעשות שימוש חלקי ו/או שימוש מלא באתר ו/או לנקוט בצעדים אחרים ו/או נוספים, על פי שיקול דעתה הבלעדי וללא צורך בהסכמת המשתמש ו/או במתן הודעה מוקדמת למשתמש, בלא שתהיה למשתמש כל טענה בנדון כנגד החברה.\n
1.7.	כל משתמש אשר יעשה שימוש כלשהו באתר ייחשב כמצהיר כי קרא את תנאי השימוש באתר, הבין אותם, מסכים להם ומתחייב לפעול על פיהם. משתמש שאינו מסכים לתנאי כלשהו מתנאי השימוש אינו רשאי לעשות שימוש באתר.
1.8.	משתמש שלא נתן הודעה מפורשת ובכתב על הסכמה לתנאי השימוש, אסור יהיה לו לעשות שימוש באתר ו/או כל חלק ממנו ו/או כל מידע שהשיג באמצעות האתר.\n
1.9.	החברה רשאית לשנות תקנון זה, בכל עת, על פי שיקול דעתה הבלעדי, מבלי למסור על כך הודעה מעבר לביצוע השינוי באתר והחובה על המשתמש באתר לבדוק טרם השימוש את תנאי השימוש. נוסח תנאי השימוש המחייב והקובע הינו הנוסח המפורסם באתר במועד השימוש הרלוונטי.\n
1.10.	תנאי שימוש אלו חלים בנוסף לכל תנאי ו/או הוראה נוספת שתתפרסם באתר, והשימוש בחלקים מסוימים באתר עשוי להיות כפוף לכללים נוספים אשר יופיעו באותם חלקים.\n
1.11.	מובהר בזאת כי השימוש בלשון זכר בתנאי השימוש נעשה מטעמי נוחות בלבד והם מיועדים לשני המינים בהתאמה.\n
1.12.	האמור תבנאי השימוש בא להוסיף על ההסכמים הפרטניים שיחתמו על ידי הלקוח ולא לגרוע מהם. בכל מקרה של סתירה יגברו תנאי ההסכם הספציפי.\n
1.13.	לבית המשפט המוסמך בתל אביב-יפו תהא הסמכות הבלעדית בכל שאלה הקשורה בפרשנותו, תחולתו, ואכיפתו של התקנון וכל סכסוך הקשור לאתר זה, והוא ידון בכך בהתאם לחוקי מדינת ישראל.\n
\n
2.	רישום ופרטי משתמש\n
2.1.	על מנת להשתמש באתר יש ליצור חשבון משתמש ולספק פרטים אישיים, כדוגמת שם, מין, תאריך לידה, כתובת, מספר תעודה מזהה, מס' טלפון, כתובת דוא"ל, העתק מסמכי זיהוי ועוד. כן יידרש המשתמש לבחור שם משתמש וסיסמה. בהשלמת תהליך הרישום על המשתמש לוודא ולאשר כי הפרטים שנמסרו על ידו מלאים ונכונים.\n
2.2.	על המשתמש חלה החובה לשמור את שם המשתמש והסיסמה בסודיות, לא להעבירם לאחר ולעשות שימוש בחשבון המשתמש שלו לצרכיו ובעצמו בלבד. כמו כן נדרש המשתמש ליידע את החברה על כל שימוש בלתי מורשה בחשבון המשתמש שלו באמצעות האתר או במשלוח הודעת דוא"ל לכתובת system@folloapp.co.il מייד עם היוודע לו על כך.\n
2.3.	המשתמש מסכים כי החברה תהיה רשאית לנקוט באמצעים שונים על מנת לאמת את זהותו ולוודא את נכונות הפרטים שנמסרו על ידו, בדרך של בקשה מהמשתמש לספק פרטים כלשהם ו/או בכל דרך אחרת, בין בעצמה ובין באמצעות צדדים שלישיים, ואי ביצוע האימות יכול וישלול את המשך השימוש באתר או קבלת השירותים מהחברה.\n
\n
3.	שירותי החברה\n
3.1.	שימוש בשירותי החברה יהיה אך ורק על ידי משתמש בגיר (שמלאו לו 18 שנים) וכשיר משפטית, בעל תעודה מזהה בתוקף (ת.ז., דרכון וכיו"ב). השירותים פונים לקהל יעד ישראלי בלבד ומטפלים במוסדות פיננסיים ישראליים.\n
3.2.	לצורך ביצוע פעולות, בהתאם להוראות הדין או בהתאם לשיקול דעתה הבלעדי של החברה, ידרש המשתמש למסור פרטים נוספים ומסמכים. במידה והמשתמש יסרב לעשות כן, תהיה רשאית החברה לאפשר או לא לאפשר את ביצוע העסקה ו/או את הקמת המשתמש (ו/או את ביטול המשתמש) ו/או שימוש באתר, לפי שיקול דעתה הבלעדי.\n
3.3.	החברה תתן ללקוחותיה את השירותים הבאים, בהתאם לעלויות שיוצגו ללקוח ויאושרו על ידו:\n
3.3.1.	בחינת נכסים ויעוץ פיננסי ראשוני – הלקוח יעביר לחברה נתונים אודות פעילותו הפיננסית בתחומים שונים בהתאם לבחירתו. כמו כן יתבקש הלקוח למלא פרטים נוספים אשר יאפשרו לחברה לבצע עבור הלקוח תכנון ראשוני ומתן המלצות והפניות לביצוע שינויים בפעילות הקיימת, והכל בהתאם להסכמה מפורשת של הלקוח.\n
3.3.2.	ביצוע שינוי בתיק הפיננסי – בהתאם להחלטת הלקוח תפעל החברה כמתווכת מול גורמים שונים למתן השירותים הפיננסיים השונים (כדוגמת סוכנויות ביטוח, יועצים פנסיונים, חברות למתן אשראי, חברות ליעוץ השקעות וניהול תיקים וכיו"ב), בהתאם לעמלות והתעריפים שיוצגו ללקוח.\n
3.3.3.	ליווי  – ככל ויבחר בכך הלקוח, יוכל הלקוח לקבל מהחברה שירות שוטף של בחינת התיק הפיננסי וביצוע עדכונים ושינויים בהתאם להמלצות החברה והסכמת הלקוח.\n
3.3.4.	שירותים נוספים – החברה תהא רשאית להציע ללקוח שירותים נוספים, המתלווים ליעוץ הכללי. יכול שהצעת השירותים הנוספים יהיו ללא קשר לתוכנית הפיננסית שהוכנה מבעוד מועד.\n
3.4.	החברה תפנה או עשויה להפנות את הלקוח במסגרת מתן השירות, לקבלת שירותים פיננסיים רלוונטיים, שלגביהם נדרש יעוץ או כל פעולה אחרת בהתאם להוראות החוק ע"י גורם מוסמך (כגון יעוץ השקעות, יעוץ פנסיוני) (להלן: "גופים מסייעים") לצורך יישום היעוץ הפיננסי הראשוני לרבות באמצעות גופים או יחידים הקשורים לחברה.\n
3.5.	במסגרת השירות וכתנאי לו, הלקוח נותן הסכמתו לחברה לקבל גישה ישירה לחשבונותיו במוסדות פיננסים לרבות בנקים, חברות אשראי וגורמים נוספים. מרגע קבלת הסכמתו המפורשת של הלקוח, יכול והמידע יוזרם אל החברה בשני אופנים חלופיים: (1) הלקוח יידרש למסור לחברה את שם המשתמש והסיסמה שלו לאתר האינטרנט של החשבון במוסד הפיננסי הרלוונטי. (2) המידע יוזרם אל החברה ישירות מהמוסד הפיננסי הרלוונטי באמצעות ממשק API אשר יוצע ע"י המוסד הפיננסי, כאשר יוצע ואם יוצע. לעניין חלופה זו יודגש כי כאשר תהיה לחברה אפשרות להתחבר לממשק כאמור, לא יהיה צורך בשימוש בשם המשתמש והסיסמה של הלקוח. אולם יודגש כי אפשרות זו תלויה בהטמעת מערכת טכנולוגית ייעודית במוסדות הפיננסיים ואין לחברה שליטה על כך. \n
3.6.	המוסד הפיננסי לא מהווה צד להתקשרות בין החברה ללקוח. הלקוח מודע היטב כי החברה אינה פועלת בשם מוסד פיננסי כזה או אחר, או מי מטעם מוסד פיננסי כלשהו.\n
3.7.	החברה אינה אחראית לוודא את מהימנות המידע או נכונותו, יודגש כי מרגע קבלת אישור הלקוח, המידע מוזרם באופן אוטומטי ממערכות המוסדות הפיננסיים בהם הלקוח מנהל את חשבונותיו אל מערכות החברה. על הלקוח מוטלת האחריות להפעיל שיקול דעת ולוודא שהמידע שקיבלה החברה מהמוסד הפיננסי והמוצג לו במסגרת שירותי החברה הוא מדויק ועדכני.\n
3.8.	הלקוח מתחייב להשיג את הסכמתם לאיסוף המידע של כל שותף או נהנה בחשבונות שאת המידע בהם הוא מספק לחברה, אם קיימים שותפים כאלה (כגון בני זוג או שותפים עסקיים). החברה לא תהיה אחראית לטענות מצד גורמים שלישיים בעניין זה אם יתגלה שהלקוח מסר לחברה הרשאה לשימוש במידע ללא הסכמת מלוא שותפיו.\n
3.9.	אם נודע ללקוח כי נאסף מידע אודות צד שלישי שהוא שותף או נהנה בחשבונותיו מבלי שהתקבלה הסכמתו המפורשת לכך, על הלקוח להודיע על כך לחברה ללא דיחוי באמצעות שירות הלקוחות.\n
3.10.	אין התנתקות משירותי החברה מהווה הודעת ביטול להסכמת הלקוח להעברת המידע או להרשאת הגישה לחשבונות במוסדות הפיננסיים שנתן הלקוח לחברה. ביטול הגישה למידע או ההרשאה ייעשו באמצעות פנייה מתאימה של הלקוח לחברה או למוסד הפיננסי הרלוונטי.\n
3.11.	במסגרת השירות, הלקוח מודע לכך כי המידע בגין חשבונותיו אשר יוזרם וירוכז אצל החברה גם ינותח על ידה ויוצג לו באמצעות ממשקי החברה.\n
3.12.	במסגרת הרשאות וייפוי כוח שייתן הלקוח לחברה, תייצג החברה את הלקוח מול גורמים פיננסיים נבחרים לרבות בנקים, גופים חוץ בנקאיים, מנהלי תיקים, יועצי השקעות, משווקי השקעות, בתי השקעות, סוכנויות וסוכני ביטוח, יצרני מוצרים פיננסיים, סוכנויות להסדרים פנסיוניים ועוד.\n
3.13.	הלקוח יהיה רשאי לחתום על ייפוי כוח המסמיך את החברה לטפל בשמו ובמקומו בכל הקשור לקבלת שירותים מהגופים הפיננסיים. מובהר כי יפוי הכח הינו אך ורק לשם פנייה בשם הלקוח, ואין בו כדי להוות יעוץ של החברה ללקוח בתחומים בהם מתחייב יעוץ ע"י גופים מייעצים.\n
3.14.	מבלי לגרוע מחובת הלקוח לעדכן את החברה בכל שינוי שיחול בקשר עם הנתונים והמידע אודותיו הנוגעים להסכם זה, הלקוח רשאי לעדכן מעת לעת את הנתונים והמידע הרלוונטיים לצורך מתן השירותים כאמור בהסכם זה ובלבד שאלה יימסרו לחברה בכתב ובחתימתו ומתן השינוי ייחשב כרלוונטי רק לאחר שהחברה אישרה ללקוח את דבר קבלת הודעת הלקוח כאמור. מקום בו העדכון האמור יצריך גיבוש מחדש של תכנית פיננסית ו/או עדכון או שינוי בשלבים שונים של השירותים הניתנים ללקוח, תעדכן החברה את היעוץ הפיננסי הראשוני ללקוח ותסוכם התמורה בנפרד.\n
3.15.	מוסכם בזאת, כי כל עוד לא תקבל החברה מהלקוח עדכון כאמור לעיל, ייחשבו הנתונים הקודמים המתועדים שמסר הלקוח כמחייבים לצורך השירותים כאמור בהסכם זה. מוסכם ומוצהר כי החלטות הנוגעות לתוכנית הפיננסית או לשינויה או ליישום או אופן היישום של היעוץ הפיננסי הראשוני יתקבלו אך ורק על ידי הלקוח ובאחריותו בלבד, בכל אחד מהשלבים המפורטים למתן השירותים וכי השירותים הנם רק בגדר הצעה לתכנון הפיננסי האמור. \n
3.16.	ייתכן כי מוסד פיננסי לא יאפשר או יפסיק את הגישה של החברה למידע של הלקוח. כמו כן ייתכן והמידע שיונגש ללקוח יהיה לא מדויק או לא עדכני שכן מדובר במערכות ממוחשבות המתקשרות ביניהן וייתכנו שיבושים או אי התאמות כתוצאה מכך. החברה לא תהיה אחראית לנכונות או עדכניות הנתונים וככל והלקוח מתכוון להסתמך על הנתונים עליו לבודקם ישירות למול המוסד הפיננסי הרלוונטי באופן ישיר.\n
3.17.	לצורך מתן שירות יפוי כח בתמורה יחתום הלקוח על יפוי כח עבור החברה לפניה ללשכת אשראי לשם קבלת דוח אשראי ו/או דירוג, כהגדרתם בחוק נתוני אשראי התשע"ו-2016. הדוח ו/או הדירוג ישמשו את החברה וכן יועברו למלווים.\n
3.18.	החברה תהיה רשאית, בכפוף להסכמת הלקוח, לספק ללקוח בהסתמך על דוח האשראי ו/או הדרוג אחד או יותר מהשירותים הבאים:\n
(1)   ייעוץ בדבר סבירות תנאי האשראי שקיבל מנותני אשראי שונים;\n
(2)   הצגת תנאי אשראי רצויים או עדיפים ללקוח לעומת תנאי האשראי שקיבל או שעשוי הלקוח לקבל;\n
(3)   ייעוץ בדבר דירוג האשראי של הלקוח וכיצד ניתן לשפרו;\n
(4)   הצגת הצעות אשראי של מלווים שונים בהתאם לחוק;\n
3.19.	מובהר בזאת כי החברה אינה מנהלת תיקי השקעות, אינה יועץ השקעות ואינה משווקת השקעות וכן אינה עוסקת בשיווק השקעות כהגדרתם בחוק היעוץ . כמו כן החברה אינה עוסקת במתן אשראי.\n
3.20.	מובהר כי החברה קשורה ובעלת זיקה לגופים המפורטים בנספח לתנאי השימוש של החברה הנמצאים באתר החברה שעניינו "גילוי נאות" ולנכסים הפיננסיים של גופים אלה. גופים קשורים אלה יכול שיעסקו או שהינם עוסקים במישרין ובעקיפין במגוון תחומי פעילות בשוק ההון ובכלל זה בהנפקת ניירות ערך, שיווק ניירות ערך ונכסים פיננסיים, במתן שירותי ביטוח, במתן שירותי אשראי, בהשקעה בניירות ערך ובנכסים פיננסיים עבור אחרים ועבור עצמם, בניהול קרנות נאמנות ובמתן שירותים אשר מותרים להם על פי דין.\n
\n
4.	שינויים באתר\n
4.1.	החברה רשאית לשנות, מעת לעת, ועל פי שיקול דעתה הבלעדי, את מבנה האתר ואת השירותים ו/או המוצרים הניתנים בו, ואף להפסיקם, וזאת ללא כל צורך בהודעה מוקדמת ו/או בהסכמת המשתמש.\n
4.2.	למשתמש לא תהא שום טענה ו/או דרישה ו/או תביעה או דרישה ביחס לשינויים אלו, או ביחס לשיבושים ו/או קשיים ו/או תקלות שיתרחשו אגב ו/או עקב ביצועם.\n
4.3.	החברה אינה מתחייבת כי השירותים באתר יינתנו כסדרם, באופן רציף וללא תקלות, והיא לא תשא בכל אחריות ו/או חבות לליקויים ו/או שיבושים ו/או תקלות ו/או הפסקות, באתר ו/או ברשת האינטרנט מכל סיבה שהיא.\n
4.4.	החברה אינה מתחייבת לשמור גיבויים לאתר ו/או למידע המתפרסם במסגרתו, ככל ויהיה כזה והיא תהא רשאית למחוק מידע מן האתר, ללא כל צורך בהודעה מוקדמת ו/או בהסכמת המשתמש.\n
\n
5.	אחריות\n
5.1.	השימוש באתר מוצע למשתמש כפי שהוא ("AS IS"). השימוש הינו לשימוש אישי של המשתמש בלבד ועל אחריותו הבלעדית. החברה אינה נושאת באחריות להתאמת האתר והמוצרים בו לצורכי המשתמש.\n
5.2.	מאחר וביצוע השירותים תלוי בצדדים שלישיים, במערכות מחשוב וכפוף להוראות הדין הישראלי והבינלאומי, יתכן והם לא ינתנו כלל או ינתנו בעיכוב או שיחולו שיבושים בהם. לא תהיה לחברה כל אחריות ישירה או עקיפה לכל נזק עקיף או ישיר שיגרם למשתמש כתוצאה מהאמור לעיל שנגרם בעטיה של החברה או בעטיו של צד שלישי.\n
5.3.	בכל מקרה אחריותה של החברה תוגבל לכל היותר לגובה תשלום חודשי של הלקוח.\n
5.4.	השירותים הניתנים ע"י החברה ניתנים לפי מיטב הבנתה וידיעתה, בהתאם לנתונים שנמסרו לה ע"י הלקוח, ומושפעים בין היתר מגורמים חיצוניים רבים, כגון מצב שוק ההון או המצב הכלכלי, ולרבות נסיבות שאינן בשליטת החברה והחברה אינה מתחייבת לתשואה מסויימת או להסדר כזה או אחר בנוגע לאשראי של הלקוח.\n
5.5.	החברה, וכן כל מי מטעמה ו/או עבורה, לרבות עובדיה, חבריה, מנהליה, נציגיה, שליחיה, בעליה, תאגידים הקשורים לה והחברות המסועפות אליה וספקיה, לא ישאו בכל אחריות בין במפורש ובין מכללא, ולא יהיו אחראים בשום מקרה ובכל דרך, לכל נזק ישיר או עקיף, מכל סוג שהוא, שיגרם למשתמש ו/או לצד שלישי כלשהו בשל שימוש באתר בכלל ו/או בשל הסתמכות על מידע ו/או שירות מכל סוג שהוא, חומר, מוצר, תוכנה וכל פרסום אחר המופיעים בהם, בפרט.\n
5.6.	החברה אינה אחראית לנכונות המידע ו/או המודעות ו/או המידע המסחרי המתפרסמים באתר ו/או לטיבו ו/או לתקפותו ו/או לשלמותו ו/או לדיוקו ו/או להתאמתו לכל מטרה שהיא והחברה לא תהיה אחראית בשום מקרה לכל נזק ישיר או עקיף, כספי או אחר, שייגרם למשתמש כתוצאה משימוש באתר, מהסתמכות על המידע שמופיע בו או באתרים חיצוניים לו.\n
5.7.	החברה אינה מתחיבת שהשירות הניתן באתר לא יופרע, יינתן כסדרו ללא הפסקות והפרעות ו/או יהיה חסין מפני גישה ללא הרשאה למחשבי החברה. במקרים כגון נזקים, קלקולים, תקלות, כשלים בחומרה, תוכנה או בקווי התקשורת אצל החברה או מי מספקיה ו/או וירוסים, רוגלות ותוכנות זדוניות למיניהן, החברה לא תהא אחראית לכל נזק, ישיר או עקיף, עוגמת נפש וכיו"ב שייגרמו למשתמש ו/או לרכושו עקב כך. \n
5.8.	החברה אינה אחראית ולא תשא בכל חבות לכל תוכן או התנהגות מאיימים, משמיצים, מגונים, פוגעים או לא חוקיים של כל גורם אחר, או לכל הפרת זכויות של גורם אחר, כולל זכויות קניין רוחני, והחברה לא תהא אחראית לכל תוכן הנשלח באמצעות ו/או נכלל באתר ע"י צד שלישי כלשהו.\n
5.9.	ייתכן והאתר יכיל קישורים אל מקורות מידע ו/או משאבים אחרים. הקישורים עשויים להשתנות מעת לעת בהתאם לשיקול דעתה של החברה. אם לא נאמר אחרת, קישורים אלו לא יתפרשו כהבעת תמיכה או מתן חסות במפורש או במשתמע על ידי החברה. אין החברה מתחייבת כי כל הקישורים שימצאו באתר יהיו תקינים. עצם הימצאותו של קישור לאתר מסוים באתר החברה אינו מלמד כי תוכן האתר המקושר הינו מהימן, מלא או עדכני, והחברה לא תישא בכל אחריות בקשר לכך, לרבות אם הקישורים מובילים לאתרים בלתי חוקיים ו/או בלתי מוסריים ו/או פוגעניים וכדו'.\n
5.10.	החברה אינה אחראית לכל שימוש שיעשה ע"י צדדים שלישיים במידע שיועבר על ידי החברה.\n
5.11.	מובהר כי המידע, הנתונים והפרטים שהלקוח מתחייב ונדרש למסור לחברה, דרושים לצורך מתן השירות. חסרון, אי דיוק, השמטה או הסתרה של כל פרט בהם עלול לפגוע בטיב השירות שתתן החברה ללקוח. החברה תראה במידע ובנתונים שימסור הלקוח כמלאים וסופיים ובהתאם להם תיתן החברה שירות ללקוח לפי מיטב ההבנה, מבלי להביא בחשבון כי קיימים נתונים, מידע, פרטים, צרכים, מטרות וסיכונים שלא הובאו לידיעת החברה כאמור.\n
5.12.	הלקוח מצהיר ומתחייב כי ביחס לכל מידע, נתונים ופרטים שנמנע למסור או לעדכן את החברה, וכן ביחס לנתונים או מידע חלקי או מוטעה, הוא מוותר ויתור סופי ומוחלט ולא תהיה לו כל טענה או תביעה או דרישה מהחברה בגין השירותים שיינתנו לו בהתאם לנתונים, המידע והפרטים הידועים לחברה, או בגין כל נזק שייגרם ללקוח כתוצאה מכך ואלה יהיו באחריות הלקוח בלבד.  \n
5.13.	המשתמש יהיה אחראי לכל נזק ישיר או עקיף, אשר ייגרם לחברה ו/או לצד שלישי כלשהו, כתוצאה מהפרת תנאי השימוש או כל דין. המשתמש מתחייב לשפות את החברה ו/או עובדיה ו/או מנהליה ו/או בעלי מניותיה ו/או נציגיה ו/או שליחיה ו/או תאגידים הקשורים לה ו/או מי מטעמם בגין כל נזק, הפסד, אבדן, תשלום או הוצאה שייגרמו להם, ובכלל זה שכ"ט עו"ד והוצאות משפט, מחמת תביעה ו/או דרישה שתופנה כלפיה, עקב פעילות המשתמש באתר ו/או הקשורה לאתר ו/או הפרה של הוראה מההוראות האמורות בתנאי שימוש אלו, ו/או של כל זכות אחרת השייכת לצד ג' כלשהו.\n
5.14.	למען הסר ספק, אחריות המשתמש כוללת גם נזקים ישירים, עקיפים, תוצאתיים, מקריים ו/או מיוחדים, לרבות פגיעה במוניטין, אבדן הכנסה, אבדן נתונים ואבדן רווחים.\n
\n
6.	זיהוי ביומטרי ביישומון\n
6.1.	החברה תפעיל שירות זיהוי באמצעות טביעת אצבע המוגדרת במכשירים סלולריים חכמים כאמצעי הזדהות בכניסה ליישומון והמשתמש יהיה רשאי לבחור להשתמש באמצעי זיהוי זה כאמצעי זיהוי חלופי במקום הקלדת שם משתמש וסיסמה בכל כניסה לאיזור האישי (להלן: "שירות זיהוי ביומטרי").\n
6.2.	שירות הזיהוי הביומטרי משתמש במנגנון הקבוע בטלפונים סלולריים חכמים של חברות שונות ובכפוף לקבוע בתקנון הפרטיות של  מפעילות הזיהוי הביומטרי.\n
6.3.	למשתמש תעמוד האפשרות להירשם לשירות הזיהוי הביומטרי לאחר הזדהות ראשונה מוצלחת בחשבונו ע"י הקלדה של שם המשתמש והסיסמה. רק לאחר הזדהות זו, יהיה על המשתמש לאשר (אם רצונו בכך) הזדהות וכניסה ליישומון באמצעות טביעת אצבע, בהתאם להגדרות המכשיר הסלולרי שברשותו.\n
6.4.	השימוש בשירות הזיהוי הביומטרי כפוף להתחייבות מצד המשתמש לכך שטביעת האצבע המוגדרת במכשיר הסלורי החכם, הוגדרה ע"י המשתמש עצמו, היא שייכת לו בלבד וכי הוא לא יגדיר במכשירו טביעות אצבע שאינן שייכות לו על עוד המשתמש עושה שימוש בשירות הזיהוי הביומטרי וביישומון. \n
6.5.	קודם לרישום לשירות הזיהוי הביומטרי ביישומון, נדרש המשתמש להסיר כל טביעת אצבע המוגדרת במכשיר ואינה שייכת לו. קיומן של טביעות אצבע של מאן דהוא אשר הוא אינו המשתמש עצמו, יכול ויחשפו את המשתמש לפריצות מצד מי שטביעת האצבע שלו מוגדרת במכשיר והוא אינו המשתמש.\n
6.6.	שירות הזיהוי הביומטרי ניתן לביטול ע"י המשתמש בכל עת באמצעות כניסה לתפריט ההגדרות ביישומון.\n
6.7.	לאחר עדכון של הסיסמה יידרש המשתמש לבצע כניסה ראשונית באמצעות שם המשתמש והסיסמה המעודכנת ובנוסף יידרש המשתמש להסכים מחדש לשימוש בשירות הזיהוי הביומטרי.\n
6.8.	החברה אינה שומרת את טביעת האצבע של המשתמשים כפי שהוגדרה במנגנון סריקת האצבע המופעלת במכשיר הסלולרי החכם, אלא טביעת האצבע נשמרת במכשיר החכם בלבד.\n
\n
7.	הגנת הפרטיות והמידע וסודיות\n
7.1.	הרשמה לאתר על ידי המשתמש תהווה אישור והסכמה להיכלל ברשימת לקוחות האתר והחברה ולקבל דיוור ישיר מאת החברה. המשתמש רשאי בכל עת לבקש להסיר את פרטיו מרשימת האתר והחברה ולבטל את הדיוור הישיר אליו והחברה תפעל להסרתו מרשימת הדיוור בתוך זמן סביר.\n
7.2.	החברה תהיה רשאית לעשות שימוש במידע באתר בהתאם לתנאי השימוש. המשתמש רשאי לפנות לחברה בכל הנוגע לעיון במידע אודותיו ותיקונו במידת הצורך. פניה כאמור תעשה בכתב באמצעות האתר או בדואר אלקטרוני system@folloapp.co.il\n
7.3.	החברה תהיה רשאית להעביר את הנתונים הרלבנטים אודות הלקוחות לגורמים שלישיים לצורך מתן השירותים והמשתמש נותן הסכמתו לכך מראש.\n
7.4.	בנוסף, החברה תהא רשאית להשתמש בפרטים שמסר המשתמש לאתר לצורך ניתוח מידע סטטיסטי עצמי ומסירת מידע סטטיסטי לצדדים שלישים. כן שומרת לעצמה החברה את הזכות לאסוף מידע הנוגע לדפוסי השימוש באתר וברשת ולעשות שימוש במידע זה לצורך שיפור השירותים באתר, הפעלתו ותכניו וכן לצורך פניה שיווקית ו/או אחרת למשתמש, התאמת האתר למשתמש עצמו ו/או לכלל המשתמשים. החברה תהא רשאית לשמור את כל סוגי המידע הנוגעים לשימוש באתר על ידי המשתמש, מכל סוג שהוא, לרבות הפרטים שמסר המשתמש במסגרת רישומו לאתר ו/או במסגרת ביצוע רכישה ו/או רישומי "לוג" הנוגעים לנתוני המשתמש (כדוגמת כתובת IP, זמן שהייה באתר, דפים בהם התעניין וכדו') ולעשות בהם שימוש כמפורט לעיל. החברה תהא רשאית לעשות שימוש בשירותי אנליזה של צדדים שלישיים לצורך ניתוח המידע והמשתמש נותן הסכמתו המפורשת לכך.\n
7.5.	החברה תהא רשאית לפנות אל המשתמש, בכתב או בעל פה, בכל אמצעי ההתקשרות שמסר, לרבות כתובתו, כתובת הדואר האלקטרוני שלו, SMS וכדו'.\n
7.6.	החברה שומרת לעצמה את הזכות לעשות שימוש בפרטי המשתמש, לרבות חשיפתם, על פי דין, ולרבות במקרים הבאים:\n
7.6.1.	אם נעשה על ידי המשתמש ו/או מי מטעמו שימוש בלתי חוקי באתר ו/או הנוגע לאתר.\n
7.6.2.	במסגרת הליכים משפטיים ו/או על פי דרישת כל רשות מוסמכת.\n
7.6.3.	לצורך אכיפת תנאי השימוש.\n
7.6.4.	לצורך הגנת אינטרסים של החברה ו/או מי מטעמה.\n
7.7.	במסגרת האתר יעשה שימוש ב"עוגיות" (Cookies) השומרות מידע הנוגע למשתמש על גבי מחשבו של המשתמש. המשתמש יכול למנוע באמצעות הדפדפן בו הוא עושה שימוש את השימוש בעוגיות, אך מובהר לו כי הדבר עלול לגרום לפגיעה בחוויית המשתמש שלו באתר.\n
7.8.	ככל ובמסגרת האתר יובאו פרסומות כלשהן שלא מטעם החברה ישירות, מובהר כי הפרסומות הינן באחריות צדדים שלישיים, אשר לחברה לא תהיה כל אחריות בנוגע אליהן, לרבות בכל הנוגע לאיסוף המידע על ידי המפרסמים האמורים ויוחלו על המשתמש הוראות תנאי השימוש של המפרסמים, לרבות בכל הנוגע למידע הנאסף ולהוראות הגנת הפרטיות והמשתמש יוכל לפעול למול הצדדים השלישיים ככל וימצא לנכון והוא מוותר בזאת על כל תביעה ו/או דרישה ו/או טענה בקשר לצדדים שלישיים אלו כנגד החברה.\n
7.9.	החברה נוקטת באמצעי זהירות מקובלים על מנת לשמור, ככל הניתן, על סודיות המידע הנמסר לה. עם זאת, אין באפשרות החברה להבטיח חסינות מוחלטת מפני חדירות בלתי מורשות לאתר ו/או שימוש אסור של צדדים שלישיים בפרטים הנמצאים באתר ו/או במאגרי המידע של האתר.\n
7.10.	במידה ולמרות אמצעי האבטחה, ייחשפו פרטי המשתמש לגורם לא מורשה, לא תהיה החברה אחראית לכל נזק, עקיף או ישיר, שייגרם כתוצאה מגילוי ו/או שימוש במידע הנמסר ע"י המשתמש.\n
7.11.	השימוש באתר הינו אישי ופרטי ועל המשתמש חלה החובה לשמור על סודיות שם המשתמש והסיסמא האישיים וכן הקוד הספציפי שניתן לביצוע פעולה. העברת שם המשתמש , הסיסמא ו/או הקוד לאדם אחר, למעט מסירת הקוד למקבל ההעברה, אף אם לא שימשה לצרכים מסחריים, אסורה בהחלט ועלולה לגרום לנקיטת סנקציות והליכים כלפי המשתמשים. מובהר בזאת כי היעדר שמירה על הסיסמאות ו/או הקוד ו/או מכשיר הסלולר בו מותקן היישומון עלולות לגרום לגניבת זהות המשתמש ו/או שימוש ו/או העברת הכספים לצדדים שאינם קשורים לעסקה והחברה לא תישא בכל נזק הקשור למסירת הקוד ו/או הסיסמא לצדדים שלישיים.\n
7.12.	על המשתמש חלה החובה ליידע את החברה על כל שימוש בלתי מורשה בשם המשתמש והסיסמא האישיים שלו, מיד עם היוודע לו דבר השימוש.\n
7.13.	במקרה של אובדן או גניבה של המכשיר הנייד או כאשר נודע על גניבת הסיסמה יש להחליף באופן מיידי את הסיסמה.\n
7.14.	טרם העברת המכשיר הנייד של הלקוח לצד שלישי יש להתנתק מהאתר/יישומון.\n
\n
8.	שימוש בלתי חוקי ואסור\n
8.1.	המשתמש מתחייב בזאת באופן בלתי מסויג לפעול בהתאם להוראות כל דין, לרבות:\n
8.1.1.	לא לכתוב כל הודעה, מידע, נתונים או כל חומר אחר, שאינו חוקי ו/או מפר הסכם או התחייבות או זכות כלשהי ו/או מזיק ו/או מאיים ו/או גם ו/או מטריד ו/או פוגע ו/או גזעני ו/או משמיץ ו/או עלול להוות הוצאת דיבה ו/או לשון הרע ו/או עלול לעלות כדי הפרת פרטיותו של אדם אחר. \n
8.1.2.	לא להתחזות לאדם ו/או לכל גורם אחר ובכלל זה לא למלא פרטים שקריים ו/או בלתי מדויקים במהלך ההרשמה לאתר, או בכל זמן אחר בעת ביקור באתר. \n
8.1.3.	לא להכניס מידע לאתר העלול לשדל משתמשים אחרים ו/או לקדם מטרה בלתי חוקית ו/או שעלולה לגרום לנזק. כמו כן, לא לשלוח חומר הכולל וירוס ו/או תוכנת מחשב העלולה לגרום נזק לאתר. \n
8.1.4.	לא להפר, בין במישרין ובין בעקיפין, הוראות כל דין. \n
8.1.5.	לא לפרסם מידע פרסומות או מידע מסחרי בכל דרך שהיא. \n
8.1.6.	לא למסור מידע המזהה אישית אנשים אחרים שלא הסכימו לכך במפורש. \n
8.1.7.	לא לפגוע בזכויות היוצרים של החברה בכל הנוגע לאתר ולתוכנו, לרבות שכפול, העתקה, הפצה, שידור וכדו' באופן מלא או חלקי.\n
8.1.8.	לא להיכנס לכל יישום ו/או חשבון ו/או קישור שאינם מיועדים למשתמש ו/או שלמשתמש אין אישור להיכנס אליהם ולא לנסות להשיג תכנים או מידע באמצעים אשר לא הועמדו לרשות המשתמש. \n
8.1.9.	לא להשתמש באתר ובתכנים באופן העלול להזיק, לשתק, לגרום לעומס יתר, או לפגוע באתר או באתר אחר, ברשתות תקשורת ובציוד תקשורת כלשהו, או להפריע לשימוש של גורם אחר כלשהו ולהנאתו מהאתר. \n
8.1.10.	לא לנסות לקבל גישה ללא הרשאה לאתר, לתכנים, לחשבונות של משתמשים אחרים או למערכות מחשבים או רשתות המחוברות לאתר בכל דרך שהיא. \n
8.1.11.	לא לאסוף, להוריד או להעתיק רשימות של משתמשים או מידע אחר לגבי משתמשים, ולא להשתמש במידע זה או להעבירו לצד שלישי כלשהו. \n
8.2.	החברה שומרת לעצמה את הזכות להפסיק ולחסום את פעילותו של משתמש באתר במידה והתעורר חשד לשימוש באתר שלא למטרה אליה הוא מיועד או במידה ולא יעמוד המשתמש בתנאי השימוש או במידה וקיימת הפרעה מכל סוג שהוא לפעילות התקינה של האתר.\n
\n
9.	קנין רוחני\n
9.1.	כל זכויות היוצרים והקניין הרוחני, מסוג כלשהו, לרבות הזכויות המוסריות לגבי הרעיון העומד ביסוד האתר, בשם ובסימן המסחרי של החברה ו/או האתר, כמו גם במבנה האתר, בעיצובו ובאופן הצגת המידע המופיע בו, תוכנו, עיצובו, עריכתו וסימניו המסחריים, וכן אופן הצגתו ועריכתו של המידע המופיע באתר תמונות, איורים, צליל, קטעי וידאו, גרפיקה, גרפים, טקסט ויישומי תוכנה ובקשר לכל פרסום, תוכן, כתבה, עיצוב, יישום, קובץ, תוכנה וכל חומר אחר, בין אם מוחשי או מופשט המתפרסם באתר או מטעמו של האתר, שייכות באופן בלעדי לחברה. תנאי השימוש מעניקים למשתמש הרשאה בלבד להשתמש באתר.\n
9.2.	בכפוף לדיני זכויות יוצרים, חל איסור להעתיק, להפיץ מחדש, לשדר, לפרסם, להציג בפומבי, למסור לצד שלישי, ליצור עבודות נגזרות, לאחסן תוכנו של האתר, באופן חלקי או מלא, זמני או קבוע, בכל צורה שהיא, או לעשות כל שימוש מסחרי או לא מסחרי, ישראלי או בינ"ל, בכל האמור לעיל, בכל אופן הנוגד את הדין, ללא הסכמה מראש ובכתב מאת המפעילה כן אסור לעשות במידע מוגן כל סילוף, או שינוי אחר, או כל פעולה העלולה לפגוע בנכונות המידע, מהימנותו או בכבודה, או בשמה של החברה כבעלת זכויות יוצרים בו, או של כל גורם אחר.\n
9.3.	מסירת מידע לפרסום באתר, באמצעות האתר ו/או בכל דרך אחרת הקשורה לאתר, על ידי המשתמש, מהווה הצהרה ואישור של המשתמש כי הוא בעל כל הזכויות הקנייניות הדרושות לשם פרסום המידע, וכי אין כל הגבלה חוקית ו/או אחרת לפרסומו ו/או למתן רשות שימוש בו על ידי החברה ו/או מי מטעמה ו/או צד שלישי.\n
\n
יצירת קשר ובירורים - באפשרות המשתמש לפנות לחברה בעניין תנאי שימוש אלה ו/או בכל עניין במשלוח הודעת דוא"ל לכתובת system@folloapp.co.il\n\n\n
מדיניות פרטיות\n
1.	הגלישה והשימוש באתר זה ו/או האפליקציה שמעמידה החברה ללקוחותיה מעת לעת ו/או כל תשתית מיכונית אחרת אחרת , על השירותים השונים אשר יינתנו בהם ו/או באמצעותם מפעם לפעם (להלן "האתר") הינם בכפוף למדיניות הפרטיות המפורטת להלן. שימוש באתר מהווה הסכמה למדיניות הפרטיות והתחייבות לפעול לפיה. בכל מקרה בו מופיעה המילה "החברה" הכוונה הינה לפולואפ פיננס 2021 בע"מ.
2.	אנא הקפד לעיין ולהתעדכן במדיניות הפרטיות להלן מאחר שעצם השימוש של כל אדם ו/או גוף הגולש ו/או צופה ו/או עושה שימוש בכל דרך אחרת, במישרין או בעקיפין באתר, לרבות בכל תוכן ו/או שירות בו, מהווים הסכמה לאמור במדיניות הפרטיות, כפי שתתעדכן מעת לעת, ללא כל הגבלה או הסתייגות, בהתאם לשיקול דעתה המלא והבלעדי של החברה. אשר על כן, טרם ביצוע פעולה כלשהי באתר, יש לקרוא בעיון רב את תנאי השימוש ומדיניות הפרטיות.
3.	חל איסור לעשות באתר ו/או בכל חלק ממנו שימוש למטרה שאיננה לצורך אישי או בלתי מסחרי.
4.	מובהר בזאת, השימוש בלשון זכר במדיניות הפרטיות, נעשה מטעמי נוחות בלבד, ואין בו כדי לפגוע ו/או ליצור אפליה כלשהי.
5.	החברה מכבדת את פרטיות המשתמשים באתר זה ולשם כך נוקטת באמצעי אבטחת מידע קפדניים בהתאם להוראות הדין החל, ובפרט בהתאם לחוק הגנת הפרטיות תשמ"א-1981 והתקנות מכוחו. מטרת התנאים המפורטים במדיניות פרטיות זו, המהווים חלק בלתי נפרד מתנאי השימוש, הינה לסקור, בין היתר, את האופן בו משתמשת החברה במידע שנמסר לה על-ידי המשתמשים באתר ו/או נאסף על-ידה בעת השימוש באתר וכן את האופן בו מבצע המשתמש שימוש בתוכן המצוי באתר.
6.	חלק מהשירותים באתר טעונים מסירת מידע אישי. במסגרת הרשמה ו/או שימוש באתר ייתכן כי תידרש למסור מידע אישי, כגון: שם, ת.ז., כתובת, מספר טלפון, כתובת דואר אלקטרוני וכיו"ב, כאשר שדות החובה יסומנו במפורש. ככל שלא יימסרו הנתונים המבוקשים בשדות החובה, לא תוכל להירשם לאתר ו/או ליהנות מחלק מהשירותים המוצעים בו. אולם יובהר, כי אין לך כל חובה חוקית למסרם.
7.	לידיעתך, במערכות החברה מתקיים מנגנון אוטומטי לרישום ותיעוד כל פעולות הלקוחות או ניסיונותיהם לביצוע פעולות וזאת על פי דין, לצרכי אבטחת מידע והגנת הפרטיות. מידע זה נשמר במאגרי החברה למשך 24 חודשים או תקופה אחרת שתיקבע בהוראות הדין.
8.	בהשארת פרטיך באתר האינטרנט או בכל אמצעי או פלטפורמה אחרים, הינך מביע את הסכמתך לכך כי הפרטים שמסרת לחברה יישמרו במאגר המידע של החברה שלה וישמשו את החברה לצורך מתן השירותים אליהם נרשמת, וכן לצורך פניה אליך והצעת מוצרים ושירותים לגביהם מסרת את פרטיך, וכן, אלא אם מסרת במפורש כי אינך מעוניין בכך, לצורך פניות נוספות בעתיד בנושאים הנוגעים למוצרים המשווקים על ידי החברה. כמו כן, עשויה העברת המידע להתבצע לצורך עיבוד המידע, מתן שירותי דיוור לחברה והעברת מידע לצורך מתן שירות למוצרים בהתבסס על מכלול המוצרים והשירותים אשר מצויים בידי החברה.
9.	בכפוף להוראות הדין, בהרשמה לאתר הנני מצהיר כי ידוע לי שהמידע נמסר מיוזמתי ובהסכמתי למטרה זו ומרצוני החופשי וכי לא חלה עלי כל חובה שבדין לעשות כן. עם זאת, מילוי חלק מהשדות בהליך הרישום לאתר מהווה תנאי הכרחי לשימוש לסיום הליך ההרשמה.
10.	מבלי לגרוע מהאמור לעיל, בכפוף להסכמתי המפורשת לחברה לקבלת דיוור ישיר ומסרים שיווקיים, תהא החברה רשאית לשלוח לי, מעת לעת, בדואר אלקטרוני ו/או  SMS ו/או באמצעי התקשרות נוספים שמסרתי, מידע בדבר שירותי החברה שלה וכן מידע שיווקי ופרסומי שלה. ידוע לי כי אהיה רשאי לבטל בכל עת את הסכמתי ולחדול מקבלת המידע כאמור, באמצעות השארת פניה בעמוד יצירת הקשר באתר האינטרנט, או באמצעות שליחת הודעה בכתובת דוא"ל  system@folloapp.co.il ,או באמצעות לחצן "הסרה" שיופיע בתחתית ההודעה שתישלח על-ידי החברה וכן אפשרות מענה באותה דרך בה מוצע המשלוח עם המילה "הסר".
11.	ככל ובמסגרת האתר יובאו פרסומות כלשהן שלא מטעם החברה ישירות, מובהר כי הפרסומות הינן באחריות צדדים שלישיים, אשר לחברה לא תהיה כל אחריות בנוגע אליהן, לרבות בכל הנוגע לאיסוף המידע על ידי המפרסמים האמורים ויוחלו על המשתמש הוראות תנאי השימוש של המפרסמים, לרבות בכל הנוגע למידע הנאסף ולהוראות הגנת הפרטיות והמשתמש יוכל לפעול למול הצדדים השלישיים ככל וימצא לנכון והוא מוותר בזאת על כל תביעה ו/או דרישה ו/או טענה בקשר לצדדים שלישיים אלו כנגד החברה.
12.	המשתמש מצהיר כי כל המידע שימסור ו/או יעדכן באתר, הינו נכון, אמין ומדויק וכן, כי ביצע את ההרשמה ו/או מסר את המידע בשמו ועבור עצמו בלבד ולא בשם ו/או עבור צדדים שלישיים, למעט במקרים בהם הורשה לעשות כן, במפורש. המידע שמסר המשתמש, אף במצב אורח, וכן מידע נוסף שיצטבר אודותיו ופעולותיו באתר ישמרו במאגרי המידע של החברה כמפורט לעיל, ויעשה בהם שימוש בהתאם למטרות המאגר המפורטות בסעיף ‎‎8 לעיל ובהתאם למדיניות פרטיות זו ובהתאם להוראות הדין. בכלל זה יעשה שימוש במידע לצורך ניהול השירות, טיפול ומעקב אחר פניות, מעקב אחר טיב השירות, הצעות לקבלת שירותים דומים ו/או משלימים, סטטיסטיקה ושיווק לרבות עיבוד המידע ודיוור ישיר למטרות אלה על-ידי החברה.
13.	החברה רשאית לאסוף וכן לעשות שימוש במידע שנמסר על-ידי המשתמש כאמור במועד ההרשמה, ו/או במידע אשר הצטבר אודות המשתמש אף במצב אורח, לרבות במהלך השימוש באתר, על מנת לשפר, להעשיר או לשנות את האתר, השירותים והתכנים המוצעים בו, וכן על מנת לעדכן את המשתמש ולערוך סקרי שביעות רצון באשר למבצעים ו/או שירותים המוצעים על-ידי החברה ו/או מי מטעמה ו/או על-ידי צדדים שלישיים עמם יש לחברה שיתוף פעולה עסקי. בנוסף, רשאית החברה לעשות שימוש במידע למטרות שיווקיות וסטטיסטיות, ולמסור מידע סטטיסטי לצדדים שלישיים, לרבות אך לא רק מפרסמים, וזאת בכפוף לתנאי מדיניות פרטיות זו. מובהר כי מידע סטטיסטי זה לא יזהה את המשתמש באופן אישי.
14.	ידוע למשתמש כי האתר משתמש בכלים אוטומטיים לצורך אפיון השימוש שלו באתר, שיפור חווית הגלישה ולצרכים שונים של אבטחת המידע והאתר. כלים אלה אוספים מידע אודותיו ואודות השימוש שלו באתר. מידע זה יכול שיכלול, בין היתר, גם את פרטי ספק שירותי האינטרנט שלו, כתובתו בפרוטוקול האינטרנט (כתובת IP), מיקום המכשיר בו נעשה שימוש בגישה לאתר, זמני ואופני השימוש באתר, סוג הדפדפן שלו ופרטים נוספים המעידים על אופן השימוש שעשה באתר, הסכמתו לשימוש במידע לצרכיה של החברה, בדיקות פנימיות וכיו"ב.
15.	החברה אף עשויה לאסוף מידע אודות שימושך באתר כגון: אופן הגעתך לאתר, משך גלישתך באתר, העמודים והתכנים בהם צפית במהלך גלישתך ועוד.
16.	יובהר, כי איסוף המידע עשוי להתבצע על ידי החברה, אך גם באמצעות ספקים של החברה, באמצעים שונים.
17.	החברה מחוייבת למחיקת מידע שאינו נדרש לצורך מימוש המטרות המפורטות לעיל, ועל כן:
17.1.	כל מידע אודות השימוש במערכת עצמה (פרטי LOG כניסה, יציאה מהאתר והשימוש בו) יישמרו למשך 24 חודשים ו/או תקופה אחרת כפי שיידרש בהוראות הדין.
17.2.	מידע שייאגר במסגרת השימוש באתר, ובכלל זה נתונים פיננסיים שיתקבלו ממקורות שונים וכל מידע אחר, יישמר לתקופה של 10 שנים ממועד סיום ההתקשרות למול הלקוח או תקופה אחרת יותר ככל ומתנהלים הליכים משפטיים, הליכי אכיפה ו/או חקירה המעכבים את מירוץ ההתיישנות על פי כל דין.
18.	האתר משתמש בעוגיות (להלן "Cookies" ו/או "עוגיות") לצורך תפעולו השוטף והתקין, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש בו, אימות פרטים, כדי להתאים את האתר להעדפות האישיות של המשתמש, לאפיין את המוצרים המתאימים לו, להתאים פרסומות מותאמות עבורו אף בגלישתו באתרים אחרים ולצרכי אבטחת מידע. עוגיות הן קבצי טקסט שהדפדפן של המשתמש יוצר לפי פקודה ממחשביה של החברה. חלק מהעוגיות יפקעו כאשר תסגור את הדפדפן ואחרות נשמרות על גבי הכונן הקשיח במחשב שלך. העוגיות מכילות מידע מגוון כדוגמת הדפים בהם ביקרת, משך הזמן ששהית באתר, מהיכן הגעת אליו, מדורים ומידע שהמשתמש מבקש לראות בעת הכניסה לאתר וכדומה. כמו כן יכול שהעוגיות ישמרו אף מידע בדבר הרגלי הגלישה של המשתמש באתרים אחרים, לרבות האתרים בהם גלש, העמודים באתרים אלה וכל פעולה אחרת בהם. המידע בעוגיות מוצפן, והחברה נוקטת צעדי זהירות על מנת לעשות כל שביכולתה שרק מחשביה ו/או מי מטעמה יוכלו לקרוא ולהבין את המידע האגור בהם.
19.	ככל שאינך רוצה לקבל עוגיות, תוכל להימנע מכך על-ידי שינוי ההגדרות בדפדפן שלך. לשם כך נא היוועץ בקובץ העזרה של הדפדפן. זכור, עם זאת, כי נטרול העוגיות עלול לגרום לכך שלא תוכל להשתמש בחלק מהשירותים המוצעים באתר.
20.	החברה נוקטת באמצעים סבירים ומקובלים על מנת לשמור ולמנוע העברת פרטים אישיים המזהים את המשתמש לצדדים שלישיים, וזאת למעט במקרים כדלקמן:
20.1.1.	בהתאם לדרישת המשתמש ו/או בהסכמתו המפורשת;

20.1.2.	במסגרת שיתופי פעולה עם חברות קשורות עסקית עם החברה;
20.1.3.	בכל מקרה בו המשתמש הפר את הוראות תנאי השימוש ו/או מדיניות הפרטיות;
20.1.4.	בשל צו שיפוטי ו/או דרישה של רשות מוסמכת למסירת המידע;
20.1.5.	בשל כל מחלוקת ו/או טענה ו/או דרישה ו/או תביעה ו/או הליכים משפטיים שיתנהלו בין המשתמש ו/או כל מי מטעמו לבין החברה ו/או מי מטעמה ו/או צד שלישי כלשהו;
20.1.6.	 בכל מקרה בו תסבור החברה כי מסירת המידע נחוצה על מנת למנוע נזק חמור לרכושם ו/או לגופם של החברה ו/או מי מטעמה ו/או משתמשים אחרים ו/או צדדים שלישיים, או על מנת למנוע נזק חמור אחר בהתאם לשיקול דעתה המלא והבלעדי;
20.1.7.	במקרה בו העבירה ו/או המחתה החברה לצד שלישי כלשהו את פעילותה ו/או את זכויותיה וחובותיה כלפי המשתמש, ובלבד שאותו צד שלישי כאמור יקבל על עצמו את ההוראות המפורטות בתנאי השימוש, מדיניות הפרטיות והוראות כל דין.
20.1.8.	מובהר, כי אין בכל האמור לעיל כדי לגרוע מזכותה של החברה להעביר לצדדים שלישים מידע שאינו אישי, אשר אין בו כדי לזהות את המשתמש אישית באופן ישיר או עקיף.
20.2.	יודגש, כי, בכפוף לכל הוראות הדין, המידע הנאסף עשוי להישמר במאגרי מידע של החברה ו/או של צדדים שלישיים הנותנים לה שירותים, המצויים בישראל ובמדינות אחרות ובכלל זה שירותי מחשוב ועבוד נתונים בענן.
20.3.	כן יצוין, כי החברה עושה ו/או עשויה לעשות שימוש באתר בכלים של צדדים שלישיים, כגון מוצרי Google  ו/או Facebook (כגון: Facebook, Google Analytics  וכן ב- Google Tag Manager) לביצוע ניתוחים בדבר השימוש להתאמת הפרסום, כאמור לעיל.
20.4.	בכלים אלו, או אחרים בהם תעשה החברה שימוש יבוצע ניתוח מידע אנונימי, סטטיסטי ואגרגטיבי אודות הגלישה והפעילות באתר.
20.5.	החברה עשויה לעשות שימוש בכלים לצורך התאמה אישית של מודעות בכלים שונים, כגון Google Signals , למשתמשי Google, שבחשבונם מופעלת האופציה להתאמה אישית של מודעות. ניתן לעיין במדיניות הפרטיות של מוצר זה בקישור:
https://support.google.com/analytics/answer/7532985?hl=iw
20.6.	החברה אף עשויה לעשות שימוש בכלים נוספים כגון AdWords לצורך התאמת הפרסום ברשת האינטרנט, וכן בניתוחים שונים דמוגרפיים ואחרים כגון Google Analytics ובכלים נוספים כגון - Facebook Pixel  לצורך התאמת פרסומות ברשתות החברתיות.
20.7.	לצורך מתן שירותים אלו עושות החברות המספקות את הכלים והמידע שימוש ב-COOKIES מטעמן, ומידע זה עשוי להישמר במאגרי המידע של חברות אלו מחוץ לישראל. ניתן למצוא מידע אודות אופן השימוש שהחברות עושות ב-COOKIES ואפשרויות העומדות לרשותך לשנות את ההגדרות באתרי החברות עצמן בקישורים הבאים:
https://www.facebook.com/policies/cookies
https://policies.google.com/technologies?hl=en
http://www.google.com/policies/technologies/types/
https://www.google.com/policies/privacy
https://www.outbrain.com/privacy/cookies
https://policies.taboola.com/en/privacy-policy
כמו כן תוכל לעשות שימוש בתוסף ביטול ההסכמה של Google Analytics המיועד להתקנה בדפדפני אינטרנט.
20.8.	החברה עושה ככל שביכולתה כדי להגן על סודיות הנתונים שנמסרים על-ידי המשתמשים באתר ולקוחותיה, תוך נקיטת אמצעי זהירות מקובלים ושימוש בטכנולוגיות אבטחה מתקדמות. ידוע למשתמש, כי החברה מקדישה משאבים ונוקטת אמצעים מחמירים למניעת חדירה לאתר ולסיכול פגיעה אפשרית בפרטיות המשתמשים, לרבות כנגד ניצול מקרי או מכוון, אובדן, הרס או גישה של אנשים בלתי מוסמכים או מורשים, אולם אין ביכולתה למנוע את האמור באופן מוחלט.
20.9.	החברה מבהירה והמשתמש מאשר ומסכים לכך שבמקרים אשר אינם בשליטתה ו/או נובעים מכוח עליון, היא איננה מתחייבת כי האתר יתנהל כסדרו וללא כל הפרעה ו/או כי האתר ו/או הנתונים שנאספו ו/או נמסרו כאמור לעיל יהיו חסינים באופן מוחלט מפני גישה ו/או חדירה בלתי מורשית למאגרי המידע של החברה. עוד ידוע למשתמש כי החברה לא תישא באחריות בגין כל נזק ו/או אובדן, ישירים או עקיפים, מכל סוג שהוא, שנגרמו כתוצאה מכך, לרבות בשל פגיעה בפרטיות.
20.10.	השימוש באתר הינו אישי ופרטי ועל המשתמש חלה החובה לשמור על סודיות שם המשתמש והסיסמא האישיים וכן הקוד הספציפי שניתן לביצוע פעולה. העברת שם המשתמש , הסיסמא ו/או הקוד לאדם אחר, למעט מסירת הקוד למקבל ההעברה, אף אם לא שימשה לצרכים מסחריים, אסורה בהחלט ועלולה לגרום לנקיטת סנקציות והליכים כלפי המשתמשים. מובהר בזאת כי היעדר שמירה על הסיסמאות ו/או הקוד ו/או מכשיר הסלולר בו מותקן היישומון עלולות לגרום לגניבת זהות המשתמש ו/או שימוש ו/או העברת הכספים לצדדים שאינם קשורים לעסקה והחברה לא תישא בכל נזק הקשור למסירת הקוד ו/או הסיסמא לצדדים שלישיים.
20.11.	על המשתמש חלה החובה ליידע את החברה על כל שימוש בלתי מורשה בשם המשתמש והסיסמא האישיים שלו, מיד עם היוודע לו דבר השימוש.
20.12.	במקרה של אובדן או גניבה של המכשיר הנייד או כאשר נודע על גניבת הסיסמה יש להחליף באופן מיידי את הסיסמה.
20.13.	טרם העברת המכשיר הנייד של הלקוח לצד שלישי יש להתנתק מהאתר/יישומון.
20.14.	אין באמור בתנאים אלה כדי לגרוע מתוקפה של כל הסכמה אחרת בין החברה לביני.
20.15.	דפי האתר וכל המידע בו הינם רכושה הפרטי של החברה, הנך רשאי להשתמש במידע למטרות פרטיות ואישיות בלבד. אין להעתיק, לפרסם, להציג בפומבי, להעמיד לרשות הציבור ולהשתמש במידע לכל מטרה אחרת, ובפרט לכל מטרה מסחרית, זכויות הבעלות במידע לרבות זכויות הפצה וכל קניין רוחני אחר במידע הינם בבעלות החברה אלא אם נאמר אחרת. הסימנים והשמות המופיעים באתר הם סימנים ושמות מסחריים קניינים של החברה ואין לעשות בהם כל שימוש שיש בו כדי לפגוע בזכויות הקניין של החברה בסימנים אלה.
20.16.	חברה הזכות לשנות, למחוק ו/או להוסיף על הוראות תנאי השימוש ומדיניות הפרטיות באתר בכל עת, על פי שיקול דעתה הבלעדי, וכל שינוי יחייב את הגולשים ממועד פרסומו באתר, או קבלת הודעה על כך מהחברה בדרך אחרת כלשהי, לפי המוקדם. שימוש באתר לאחר ביצוע השינויים כאמור יעיד על הסכמה לשינויים אלה. ככל והמשתמש אינו מסכים לשינויים, עליו להימנע מהמשך שימוש באתר.
20.17.	נוסח תנאי השימוש ומדיניות הפרטיות המחייב והקובע בכל מועד הינו הנוסח המפורסם באתר. תוקף הוראות תנאי השימוש ומדיניות הפרטיות הינו מצטבר ולא לחילופין והן יפורשו כמתקיימים זה לצד זה ולא מצמצמים זה את תחולת זה.
20.18.	בכל שאלה ו/או הבהרה ו/או תגובה בנוגע למדיניות פרטיות זו או אם ברצונך לעיין לשנות או לתקן את המידע האישי המצוי אצלנו אודותיך, אנא פנה אלינו לדוא"ל system@folloapp.co.il. בכל תכתובת יש לכלול את פרטיך המלאים לרבות כתובת מגורים וכתובת דואר אלקטרוני / טלפון נייד ליצירת קשר. החברה תשתדל להגיב לכל בקשה סבירה תוך זמן סביר.
20.19.	תוכל למצוא מידע נוסף אודות הוראות הדין הישראלי וזכויותיך מכוחו בתחום הגנת הפרטיות באתר הרשות להגנת הפרטיות בכתובת https://www.gov.il/he/Departments/the_privacy_protection_authority
20.20.	יובהר, כי סמכות השיפוט בכל הנוגע למסמך מדיניות זה נתונה לבתי המשפט בישראל, באזור המרכז, בהתאם לסמכות שיפוטם.
20.21.	מפתח היישומון הינו folloappservices.2021@gmail.com ואליו ניתן לפנות בכל סוגיה הנוגעת ליישומון.
20.22.	כמו כן יובהר, כי האמור במדיניות הפרטיות של החברה נכתב בלשון זכר מטעמי נוחות בלבד.

`,
    },
}