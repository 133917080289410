import React from "react";

export default function SimpleLoader({ color = "black", size = 24, ...props }) {
    return (
        <div
            {...props}
            style={{
                borderTop: `${size / 8}px solid rgba(255, 255, 255, 0.2)`,
                borderRight: `${size / 8}px solid rgba(255, 255, 255, 0.2)`,
                borderBottom: `${size / 8}px solid rgba(255, 255, 255, 0.2)`,
                borderLeft: `${size / 8}px solid ${color}`,
                borderRadius: "50%",
                width: size,
                height: size,
                animation: "spin 1s linear infinite",
            }}
        />
    );
}
