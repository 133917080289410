import { SimpleContainer } from "../../../components/simpleComponents/SimpleContainer";
import SimpleImage from "../../../components/simpleComponents/SimpleImage";
import { Text16 } from "../../../components/styledComponents/texts/Text16";
import { Text20 } from "../../../components/styledComponents/texts/Text20";

export default function ImageWithLabelAndContent({ ListOf, ContainerStyle, ContentStyle }) {
    return (
        <SimpleContainer style={ContainerStyle}>
            {ListOf.map((item, index) => (
                <SimpleContainer key={index} style={ContentStyle}>
                    <SimpleImage
                        src={item.image}
                        style={{ height: item.height }}
                    />
                    <Text20 style={{ textAlign: 'center', marginTop: 32 }}>{item.title}</Text20>
                    <Text16 style={{ textAlign: 'center', marginTop: 16 }}>{item.content}</Text16>
                </SimpleContainer>
            ))}
        </SimpleContainer>
    );
}
