import React, { useState, useRef, useEffect } from 'react';
import { SimpleContainer } from '../../simpleComponents/SimpleContainer';
import ImageButton from '../../simpleComponents/ImageButton';
import { icons } from '../../../assets/icons/icons';
import HeaderLinksList from './HeaderLinksList';
import LoginButtons from './LoginButtons';
import { MainScreenData } from '../../../pages/mainScreen/MainScreenData';

const { Links, Login } = MainScreenData;

export default function LeftToRightDrawer({ HandleScrollToPages }) {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    function HandleScrollToPagesSmall(pageName) {
        setIsOpen(false);
        HandleScrollToPages(pageName)
    }

    return (
        <div style={styles.container}>
            <ImageButton image={icons.Menu} imageStyle={styles.menuButton} onPress={toggleMenu} />
            {isOpen && (
                <div ref={menuRef} style={styles.menu}>
                    <LoginButtons LoginButtons={Login} style={{ justifyContent: 'center', marginBottom: 16 }} />
                    <HeaderLinksList ListOfButtons={Links} style={{}} HandleScrollToPages={HandleScrollToPagesSmall} />
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center'
    },
    menuButton: {
        width: 24,
        height: 24,
    },
    menu: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -30%)',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '16px',
        boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)',
        zIndex: '1000',
        transition: 'transform 0.3s ease',
        justifyContent: 'center'
    },
};
