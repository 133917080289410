import React from 'react';

export default function SimpleButton({ controlId, children, textStyle, onPress, disabled, ...props }) {
    function handlePress() {
        if (!disabled) {
            onPress?.();
        }
    }

    return (
        <button
            {...props}
            onClick={handlePress}
            disabled={disabled}
        >
            <span style={textStyle}>{children}</span>
        </button>
    );
}
