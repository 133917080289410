import TertiaryButton from "../../styledComponents/buttons/generic/TertiaryButton";
import { buttonSizes } from "../../../styles/buttons/buttonSizes";
import { SimpleContainer } from "../../simpleComponents/SimpleContainer";
import { useEffect, useState } from "react";

export default function HeaderLinksList({ ListOfButtons, HandleScrollToPages }) {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <SimpleContainer>
            {ListOfButtons.map((item, index) => (
                <TertiaryButton
                    key={index}
                    size={buttonSizes.MEDIUM}
                    onPress={() => HandleScrollToPages(width < 1480 ? item.pageName + "Small" : item.pageName)}
                >
                    {item.text}
                </TertiaryButton>
            ))}
        </SimpleContainer>
    );
}
