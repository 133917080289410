import React from "react";
import DefaultText from "./DefaultText";
import { colors } from "../../../styles/colors";

export default function LiroyText({
    color = colors.text,
    size = 14, // Default font size
    fontFamily = 'Fredoka', // Font family
    fontWeight = 400,
    style,
    children,
    ...restProps // Other props
}) {
    const textStyle = {
        color,
        fontSize: size,
        fontFamily,
        fontWeight,
        ...style,
    };

    return (
        <DefaultText style={textStyle} {...restProps}>
            {children}
        </DefaultText>
    );
}
